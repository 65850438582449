import { useEffect } from 'react';

import './styles.scss';

export default ({ }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className='disclaimer'>
      <div className='disclaimer_title'>
        Disclaimer
      </div>
      <div className='disclaimer_body'>
        <p>
          This video has been taken from related source link.
        </p>
        <p>
          We do not intend to achieve any unjust benefit from the use of this
          content, rather we are promoting the brand from our small level to reach
          the petty consumers in an efficient way. We respect the confidentiality of
          the original content creator and have put the content in as it is condition
          without any modification.
        </p>
      </div>
    </div>
  );
}