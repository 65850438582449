import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  ActiveOfferBanners {
    activeOfferBanners {
      _id
      title
      link
      image
    }
  }
`;

export default () => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'activeOfferBanners',
    fetchPolicy: 'cache-and-network',
    defaultValue: []
  });

  return { activeOfferBannersResult: result, activeOfferBannersLoading: loading, activeOfferBannersError: error };
}