import { useEffect, useContext } from "react";
import { Row, Col, Pagination } from "antd";

import { CategoryContext } from "context/CategoryContext";

import useActiveProductsWithPagination from "hooks/product/useActiveProductsWithPagination";

import { extractValueFromQueryString } from "utils/util";

import config from "configs/config";

import FilterSidebar from "./FilterSidebar";
import ProductCard from "components/Card/ProductCard";
import SpinnerLoader from "components/Loader/SpinnerLoader";

import "./styles.scss";

export default ({ history, match, location }) => {
  const urlCategory = match.params.category;
  const urlSearch = extractValueFromQueryString(location.search, "query");

  let page = extractValueFromQueryString(location.search, "page");
  if (!page) {
    page = 1;
  }
  const limit = 12;

  const { activeCategoriesLoading, activeCategoriesResult } =
    useContext(CategoryContext);

  const getActiveCategory = (categorySlug) => {
    let activeCategory = null;

    if (
      categorySlug &&
      !activeCategoriesLoading &&
      activeCategoriesResult &&
      activeCategoriesResult.length
    ) {
      const [selectedCategory] = activeCategoriesResult.filter(
        (ac) => ac.slug === categorySlug
      );

      if (selectedCategory) {
        activeCategory = selectedCategory;
      }
    }

    return activeCategory;
  };

  const {
    activeProductsWithPaginationTrigger,
    activeProductsWithPaginationResult,
    activeProductsWithPaginationLoading,
  } = useActiveProductsWithPagination();

  const getFilterParamsObject = () => {
    let filterParamsObject = {};

    if (location.search) {
      const queryStringObject = extractValueFromQueryString(location.search);

      if (queryStringObject) {
        Object.keys(queryStringObject).forEach((qso) => {
          let value = queryStringObject[qso];
          if (qso === "brandfilter") {
            value = value.split(",");
          }
          if (qso === "pricefilterstart" || qso === "pricefilterend") {
            value = parseInt(value);
          }
          filterParamsObject[qso] = value;
        });
      }
    }

    return filterParamsObject;
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    let params = {};

    if (urlCategory) {
      params.categorySlug = urlCategory;
    }

    if (urlSearch) {
      params.search = decodeURI(urlSearch);
    }

    const filterParamsObject = getFilterParamsObject();

    if (filterParamsObject && Object.keys(filterParamsObject).length) {
      Object.keys(filterParamsObject).forEach((fpo) => {
        params[fpo] = filterParamsObject[fpo];
      });
    }

    //pagination update
    params.page = parseInt(page, 10);
    params.limit = limit;

    activeProductsWithPaginationTrigger(params);
  }, [urlCategory, urlSearch, location.search]);

  const applyFilter = (filterParams) => {
    const filterParamsArray = Object.keys(filterParams).map(
      (fp) =>
        `${fp}=${
          Array.isArray(filterParams[fp])
            ? filterParams[fp].join(",")
            : filterParams[fp]
        }`
    );

    if (filterParamsArray.length) {
      history.push(`?${filterParamsArray.join("&")}`);
    }
  };

  const clearFilter = () => {
    history.push(urlCategory);
  };

  const changePage = (page) => {
    let filterParams = {};

    const filterParamsObject = getFilterParamsObject();

    if (filterParamsObject && Object.keys(filterParamsObject).length) {
      Object.keys(filterParamsObject).forEach((fpo) => {
        if (fpo !== "page" && fpo !== "limit") {
          filterParams[fpo] = filterParamsObject[fpo];
        }
      });
    }

    const filterParamsArray = Object.keys(filterParams).map(
      (fp) =>
        `${fp}=${
          Array.isArray(filterParams[fp])
            ? filterParams[fp].join(",")
            : filterParams[fp]
        }`
    );

    if (filterParamsArray.length) {
      history.push(`?${filterParamsArray.join("&")}&page=${page}`);
    } else {
      history.push(`?page=${page}`);
    }
  };

  return (
    <div className="productlist">
      {activeCategoriesLoading ? (
        <SpinnerLoader />
      ) : getActiveCategory(urlCategory) ? (
        <div
          className="productlist_title"
          style={{
            backgroundImage: `url(${config.assetURL}/${
              getActiveCategory(urlCategory)["image"]
            })`,
            padding: "150px 0px",
          }}
        >
          <div className="productlist_title_overlay" />
          <div className="productlist_title_text">
            {getActiveCategory(urlCategory)["title"]}
          </div>
        </div>
      ) : urlSearch ? (
        <div className="productlist_title" style={{ padding: "100px 0px" }}>
          <div className="productlist_title_text">Search</div>
        </div>
      ) : null}
      <div className="productlist_body">
        <Row gutter={[24, 24]}>
          <Col md={6} xs={24}>
            <div className="productlist_body_filter">
              <FilterSidebar
                urlCategory={urlCategory}
                totalProducts={
                  activeProductsWithPaginationResult &&
                  activeProductsWithPaginationResult.total
                    ? activeProductsWithPaginationResult.total
                    : 0
                }
                filterParamsObject={getFilterParamsObject()}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
              />
            </div>
          </Col>
          <Col md={18} xs={24}>
            <div className="productlist_body_list">
              <div className="productlist_body_list_breadcrumb">
                Home &gt;{" "}
                {urlCategory
                  ? `Category > ${urlCategory.replaceAll("-", "")}` || null
                  : "Search"}
              </div>
              {activeProductsWithPaginationLoading ? (
                <SpinnerLoader />
              ) : activeProductsWithPaginationResult ? (
                <>
                  <div className="productlist_body_list_items">
                    <Row gutter={[24, 24]}>
                      {activeProductsWithPaginationResult.data.map((p) => (
                        <Col key={p._id} md={8} xs={24}>
                          <ProductCard
                            category={p.category.slug}
                            image={p.image}
                            name={p.title}
                            actualPrice={p.price}
                            offerPrice={p.offerPrice}
                            slug={p.slug}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <div className="productlist_body_list_pagination">
                    <Pagination
                      current={parseInt(page)}
                      pageSize={limit}
                      total={activeProductsWithPaginationResult.total}
                      onChange={(page) => changePage(page)}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
