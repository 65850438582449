import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from 'services/auth';

import routeConfig from 'Routes/config';

export default ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => {

      if (isAuthenticated()) {
        return <Redirect push to={{ pathname: routeConfig.dashboard.path, search: props.location.search, state: { from: props.location } }}/>;
      }

      return <Component {...props} {...rest} />
    }} />
  );
}