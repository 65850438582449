import { createContext } from "react";

import useActiveSetting from "hooks/setting/useActiveSetting";

export const SettingContext = createContext({ activeSettingLoading: true, activeSettingResult: [] });

export const SettingProvider = ({ children }) => {
  const { activeSettingResult, activeSettingLoading } = useActiveSetting();

  return (
    <SettingContext.Provider value={{ activeSettingLoading, activeSettingResult }}>
      {children}
    </SettingContext.Provider>
  );
}