import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  ActiveCategories {
    activeCategories {
      _id
      title
      slug
      image
    }
  }
`;

export default () => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'activeCategories',
    fetchPolicy: 'cache-and-network',
    defaultValue: []
  });

  return { activeCategoriesResult: result, activeCategoriesLoading: loading, activeCategoriesError: error };
}