import { useEffect } from 'react';
import { Modal, Form, Row, Col, Alert, Button } from 'antd';

import useInquiryCreate from 'hooks/inquiry/useInquiryCreate';

import * as validation from 'utils/validation';

import TextInput from 'components/Input/TextInput';

import './styles.scss';

export default ({ product, visible, onClose }) => {
  const [form] = Form.useForm();

  const { inquiryCreateTrigger, inquiryCreateLoading, inquiryCreateResult, inquiryCreateError } = useInquiryCreate();
  const submitFormData = (formData) => {
    formData.product = product;

    inquiryCreateTrigger(formData);
  };

  useEffect(() => {
    if (!inquiryCreateLoading) {
      if (inquiryCreateResult && inquiryCreateResult._id) {
        form.resetFields();
        onClose();
      }
    }
  }, [inquiryCreateLoading, inquiryCreateResult]);

  return (
    <Modal visible={visible} onCancel={onClose} footer={null} wrapClassName='inquirymodal'>
      <div className='inquirymodal_title'>
        Please submit your mobile number and we will get back to you.
      </div>
      <div className='inquirymodal_form'>
        <Form
          className="row-col"
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={submitFormData}
        >
          <Row gutter={[24, 0]}>
            <Col md={24}>
              <TextInput
                name='mobile'
                placeholder='Mobile Number'
                rules={[
                  {
                    required: true,
                    message: "Please input mobile number!",
                  },
                  () => ({
                    validator(_, value) {
                      if (value && value !== '') {
                        if (!validation.mobile(value)) {
                          return Promise.reject(new Error('Invalid mobile!'));
                        }
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              />
            </Col>
            {inquiryCreateError ?
              <Col md={24}>
                <Alert message={inquiryCreateError} type='error' style={{ marginBottom: 24 }} />
              </Col>
              :
              null}
            <Col md={24}>
              <Form.Item wrapperCol={{ span: 12 }}>
                <Button type="primary" htmlType="submit" loading={inquiryCreateLoading} block>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}