import { useContext, useEffect } from 'react';

import { SettingContext } from 'context/SettingContext';

import config from 'configs/config';

import './styles.scss';
import ManagingDirector from 'assets/images/ManagingDirector.jpg';

export default ({ }) => {
  const { activeSettingResult } = useContext(SettingContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className='aboutus'>
      <div className='aboutus_title'>
        About Us
      </div>
      <div className='aboutus_body'>
        <div className='aboutus_body_top'>
          <div className='aboutus_body_top_logo'>
            {activeSettingResult && activeSettingResult.logo ?
              <img src={`${config.assetURL}/${activeSettingResult.logo}`} alt='logo' />
              : null
            }
          </div>
          <div className='aboutus_body_top_text'>
            Nirmangurunepal.com is the first mover as well as largest e-commerce in Construction field where it provides the <b>largest Business to Business (B2B) as well as Business to Consumers (B2C) marketplaces</b> in Nepal. It’s the only platform which provides all Construction related solutions under one roof such as hardware, electrical, sanitary, plumbing, machinery and building materials via web portal in Nepal.
          </div>
        </div>
        <div className='aboutus_body_detail'>
          <div className='aboutus_body_detail_title'>
            Why Nirman Guru Nepal?
          </div>
          <div className='aboutus_body_detail_text'>
            Construction is a wide and complicated sector with various brands, product categories and prices. And more varieties create more confusion, and more confusion means waste of our precious time.
            <br />
            <br />
            We are mainly focused to give everyone right product at ideal cost and at correct time at desired destination whether it’s small or huge projects to tackle this well-established issue of settling on inappropriate choices during construction works.
            <br />
            <br />
            The website has special features for professional B2B customers as it helps them to track the progress of their individual construction sites for the expenses made, quantity and date of purchases made which again helps to manage one’s project very efficiently. Indeed, even the costs for single or numerous pieces of purchases made remain the same because of which the business clients can undoubtedly buy exactly what they need for the time, accordingly, assisting with keeping their Cash Flow as well.
          </div>
        </div>
        <div className='aboutus_body_message'>
          <div className='aboutus_body_message_image'>
            <img src={ManagingDirector} />
          </div>
          <div className='aboutus_body_message_text'>
            <div className='aboutus_body_message_text_title'>
              Message from the Founder / CEO
            </div>
            <div className='aboutus_body_message_text_text'>
              <b>What is life, but a mere struggle! </b>
              <br />
              <br />
              I remember the days when I used to be on the line of struggle and burning myself with experience. I feel proud to shift from being ‘on the line’ to being ‘online’ after more than 15 years of hardships in this field. Construction has been my keen area of interest from my early days. And so we are here with the objective to reach the end user. Our ultimate goal is to provide service and facilities to the end user.
              <br />
              <br />
              I extend my gratitude to you all for visiting our website. Looking forward for your feedback and continuous support. Welcome to Nirman Guru Nepal.
              <br />
              <br />
              <br />
              <b>Ravi Jung Bista</b>
              <br />
              Founder / CEO
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}