import { Layout } from 'antd';

import Header from './Header';
import Footer from './Footer';

import './styles.scss';

export default ({ children }) => {
  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.Content>
        {children}
      </Layout.Content>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </Layout>
  );
}