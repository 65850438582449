import useGraphQLLazyQuery from "hooks/common/useGraphQLLazyQuery";

const query = `
  ActiveProductsWithPagination($page: Int!, $limit: Int!, $categorySlug: String, $brandfilter: [String], $pricefilterstart: Int, $pricefilterend: Int, $search: String) {
    activeProductsWithPagination(page: $page, limit: $limit, categorySlug: $categorySlug, brandfilter: $brandfilter, pricefilterstart: $pricefilterstart, pricefilterend: $pricefilterend, search: $search) {
      page
      limit
      total
      data {
        _id
        category {
          _id
          slug
        }
        title
        slug
        price
        offerPrice
        image
        video
      }
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLLazyQuery({
    query,
    key: 'activeProductsWithPagination',
    fetchPolicy: 'cache-and-network',
    defaultValue: []
  });

  return { activeProductsWithPaginationTrigger: trigger, activeProductsWithPaginationResult: result, activeProductsWithPaginationLoading: loading, activeProductsWithPaginationError: error };
}