import { useEffect } from "react";

import "./styles.scss";

export default ({}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacynotice">
      <div className="privacynotice_title">Privacy Notice</div>
      <div className="privacynotice_body">
        {/* <p>
          The words "we," "our" and "us" are referred to as "www.nirmangurunepal.com"
          and identify personally identifiable or personal information which can be
          collected, how the data is used and how you choose to use the information
          that we use, which are described in this Policy.
        </p>
        <p>
          We intend to protect your privacy in relation to the terms set out in this
          website’s Terms of Use Agreement. Only authorized nirmangurunepal members
          to use any details provided by you or your agent. In order to secure
          personal information, we continuously monitor our systems and records. In
          order to prosecute and/or pursue civil proceedings to recover damages
          against the liable parties, we investigate all allegations and such acts.
          Any user not agree to any of the conditions hereof shall immediately leave
          the website. Nirmangurunepal disclaims all liabilities arising thereof,
          provided you do not agree with the policy, and you still have access to the
          website and provide your personal information.
        </p>
        <p>
          <strong>Applicability</strong>
          :
        </p>
        <p>
          This privacy policy extends to all users who visit the website and are
          therefore expected before sending any personally identifiable information
          to read and understand the Privacy Policy. You may access, modify or remove
          any information that you store on your behalf if you have inadvertently
          submitted any such information to Nirmangurunepal before reading the data
          protection information provided herein and you do not agree with the way
          such information is collected, stored or used. Nirmangurunepal retains the
          personally identifiable information of the user, and each user is allowed
          to request that Nirmangurunepal remove and delete these data (but not other
          users).
        </p>
        <p>
          Certain users can access the website, position orders and make use of
          services without registering or providing any personal information.
          Nirmangurunepal is not responsible for or validates the information
          provided by the visitor unless required by law, regulation or an order of
          the competent authority. A user has to create an account on our website in
          order to have access to all the features and benefits of our website. A
          user must provide personal information as required in the Request /
          Registration Form in order to create an account. Additional information,
          including the possibility of receiving promotions from nirmangurunepal, is
          optional on the registration page. In addition, nirmangurunepal can include
          more optional user requests to help nirmangurunepal configure the website
          to provide the user with customized data. Nirmangurunepal shall not
          exchange, rent, or sell any personal information to third parties other
          than that which is stated in this Data Protection Policy, without the
          consent of the User. Nirmangurunepal can record received and made telephone
          calls for the purpose of providing services efficiently and effectively for
          inquiries, orders, suggestions or other purposes.
        </p>
        <p>
          Personal information refers to details that identify the user, name, email
          address, phone number, location information, Google Map information, last
          login time and webpage or any financial information provided at or after
          the registration to nirmangurunepal.
        </p>
        <p>
          This Privacy Policy extends to nirmangurunepal - owned websites and
          services. Nirmangurunepal shall not control sites displayed in its services
          like search results or links. Websites of a third party that place their
          own cookies or any other files in the computer of the user, collect data or
          solicit from users that are not responsible or liable to nirmangurunepal.
          We, therefore, does not reflect, or guarantee, the accuracy, integrity or
          quality of any information, data, text, software, sound, images, graphics,
          videos and any other material available on those sites in any manner in
          relation to the privacy practices or policies of such third persons or to
          any terms and conditions of use of such websites. Nirmangurunepal does not
          include or exclude any approval of the website, the provider of the website
          or the details on the website. Nirmangurunepal requests the user to read
          the website's privacy policies.
        </p>
        <p>
          In order to safeguard against unauthorized access, improper use or release,
          change or illegal destruction or unintentional loss of Personal
          information, nirmangurunepal took all reasonable precautions to handle
          personal data as confidentially subject to standards set by the industry.
        </p>
        <p>
          <strong>Use of your personal data</strong>
          :
        </p>
        <p>
          You or your designated representative will provide your personal
          information to nirmangurunepal during the use of our services. You can
          request us to modify or delete this information if this information is
          incorrect. Nirmangurunepal shall take all appropriate measures to ensure
          that information is modified and used for the provision of services, in
          accordance with the law, to you. We may ask you to verify your identity
          before we can act on your request when editing or deleting personal
          information.
        </p>
        <p>
          <strong>Exceptions.</strong>
          We can share your personal data with third parties having the need or
          authority to obtain such information if we have a good faith belief that it
          is reasonably necessary to have access, use, protection or communication of
          information in order to comply with (i) in relation to receiving of such
          information under law by any authority (ii) any court order (iii) detect or
          stop fraud, security or technical problems, or otherwise address fraud (iv)
          to protect nirmangurunepal, our users, or the public against harm to the
          rights, properties or health, as required by law or allowed by law.
        </p>
        <p>
          If you want to use any of the services provided on the site and use it to
          identify yourself, you agree to provide us with your personal data.
          Nirmangurunepal representatives may request such personal information (e.g.
          full legal name, address, products purchased, etc.) and other information
          that may be required from time to time, via email, telephone or letter.
          When nirmangurunepal is using personal information and performing some
          review or interpretation by a trained officer the patterns in your personal
          information and lifestyle, you allow us to share it with our expert for a
          limited purpose of his understanding and validation, nevertheless,
          nirmangurunepal will own these data and records exclusively, unless you
          choose to purchase the same for a certain amount payable to
          nirmangurunepal.
        </p>
        <p>
          Nirmangurunepal makes no unauthorized calls or otherwise markets any goods
          or services except for the reason for which such information is submitted
          or for taking feedbacks or addressing queries. The user shall not reveal
          his or her personal data to any non-approved third party and check the
          identity of those individuals who seek information. Nirmangurunepal can
          communicate with users through e-mail and online updates, or via other
          means, which includes text and other messaging types including Whatsapp,
          Viber, etc. Through signing in to your account, users can always change
          their e-mail and contact preferences or calling customer support.
        </p>
        <p>
          If you so wish, nirmangurunepal can send you direct mail at the address you
          have submitted. The links at the bottom of every mailer are to allow you to
          opt-out of this direct mailer. We value your privacy and so far as you do
          not accept these mailers, all steps will be taken to delete you from the
          list. When you see that our terms and conditions are violated, please write
          to us, or call us and report these incidents at our call center. All
          information, including sensitive personal information, given to
          nirmangurunepal either by the user directly or through our designated agent
          is real, precise and voluntary. In accordance with the provisions of this
          Privacy Policy and the terms of use, the user has the right to withdraw
          information at any time by writing to us.
        </p>
        <p>
          For research, statistical analysis and business intelligence purposes,
          nirmangurunepal may use personal information without regard to any
          individual or identity and the data, statistics or intelligence data may be
          sold or otherwise transferred to third parties and affiliates in an
          aggregated or non-personally identifiable form. The confidentiality of all
          sensitive personal data and information of each user is to be preserved by
          all nirmangurunepal employees and data processors who have access to or
          related to the processing of, sensitive personal data or data.
        </p>
        <p>
          In the case of a reorganization or sale of the assets, nirmangurunepal may
          also report or transfers personal and other information provided by the
          user to another third party. Any third party to which nirmangurunepal
          passes or sells its properties shall be entitled to further use the users'
          personal and other details.
        </p>
        <p>
          Nirmangurunepal may supply its personal information to third parties
          contractors working on behalf of or with nirmangurunepal to the extent
          necessary for the provision of services on this website to users, to
          support nirmangurunepal in connecting or sustaining the website with users.
          These contractors generally have no separate right to share this
          information, however, certain contractors providing website services,
          including online communications services, shall have the right to use and
          reveal, in compliance with their own privacy policy, personal information
          obtained in respect of the provision of these services.
        </p>
        <p>
          At any time, with or without notice nirmangurunepal can update or amend
          this privacy policy. In the event that significant changes occur in the way
          nirmangurunepal handles a user’s information which can identify the user,
          nirmangurunepal displays a notification on the website or email users about
          it. All information nirmangurunepal has about users and their accounts are
          covered by the current Privacy Policy of nirmangurunepal.
        </p>
        <p>
          Nevertheless, nirmangurunepal is not required to inform users of any
          modifications made to the Privacy Policy. You may refuse to accept the
          amendments and choose the removal of your personal data by writing to us if
          you have any questions or do not accept changes to your privacy policy.
        </p>
        <p>
          Please write to us if you have any compliance or complaints regarding the
          website or this privacy policy.
        </p> */}
      </div>
    </div>
  );
};
