import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  ActiveCategoriesWithFeaturedProducts {
    activeCategoriesWithFeaturedProducts {
      _id
      title
      slug
      image
      products {
        _id
        category {
          _id
          slug
        }
        title
        slug
        sku
        price
        offerPrice
        description
        image
      }
    }
  }
`;

export default () => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'activeCategoriesWithFeaturedProducts',
    fetchPolicy: 'cache-and-network',
    defaultValue: []
  });

  return { activeCategoriesWithFeaturedProductsResult: result, activeCategoriesWithFeaturedProductsLoading: loading, activeCategoriesWithFeaturedProductsError: error };
}