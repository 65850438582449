import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faEnvelope, faPhoneAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

import { SettingContext } from 'context/SettingContext';

import routeConfig from 'Routes/config';

import config from 'configs/config';

import SpinnerLoader from 'components/Loader/SpinnerLoader';

import './styles.scss';

export default ({ }) => {
  const { activeSettingLoading, activeSettingResult } = useContext(SettingContext);

  return (
    <div className='footer'>
      <div className='footer_logo'>
        {activeSettingLoading ?
          <SpinnerLoader />
          :
          activeSettingResult ?
            <img src={`${config.assetURL}/${activeSettingResult.logo}`} alt='logo' />
            :
            null}
      </div>
      <div className='footer_links'>
        <Row>
          <Col md={8} xs={24}>
            <div className='footer_links_title'>
              Contact Us
            </div>
            {activeSettingLoading ?
              <SpinnerLoader />
              :
              activeSettingResult ?
                <div className='footer_links_items'>
                  <div className='footer_links_items_single'>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> {activeSettingResult.address}
                  </div>
                  <div className='footer_links_items_single'>
                    <FontAwesomeIcon icon={faEnvelope} /> {activeSettingResult.infoEmail}
                  </div>
                  <div className='footer_links_items_single'>
                    <FontAwesomeIcon icon={faPhoneAlt} /> {activeSettingResult.primaryPhone}, {activeSettingResult.secondaryPhone}, {activeSettingResult.officePhone}
                  </div>
                  <div className='footer_links_items_single'>
                    <FontAwesomeIcon icon={faClock} /> {activeSettingResult.officeTime}
                  </div>
                </div>
                :
                null
            }
          </Col>
          <Col md={8} xs={24}>
            <div className='footer_links_title'>
              Information
            </div>
            <div className='footer_links_items'>
              <div className='footer_links_items_single'>
                <Link to={routeConfig.termsAndCondition.path}>
                  Terms and Conditions
                </Link>
              </div>
              <div className='footer_links_items_single'>
                <Link to={routeConfig.privacyNotice.path}>
                  Privacy Notice
                </Link>
              </div>
              <div className='footer_links_items_single'>
                <Link to={routeConfig.aboutUs.path}>
                  About Us
                </Link>
              </div>
              <div className='footer_links_items_single'>
                <Link to={routeConfig.contactUs.path}>
                  Contact Us
                </Link>
              </div>
              <div className='footer_links_items_single'>
                <Link to={routeConfig.disclaimer.path}>
                  Disclaimer
                </Link>
              </div>
            </div>
          </Col>
          <Col md={8} xs={24}>
            <div className='footer_links_title'>
              Social Media
            </div>
            {activeSettingLoading ?
              <SpinnerLoader />
              :
              activeSettingResult ?
                <div className='footer_links_items'>
                  <div className='footer_links_items_single'>
                    {activeSettingResult.facebookLink ?
                      <Link to={{ pathname: activeSettingResult.facebookLink }} target='_blank'>
                        <FontAwesomeIcon icon={faFacebook} size={35} style={{ fontSize: '24px' }} />
                      </Link>
                      :
                      null
                    }
                    {activeSettingResult.instagramLink ?
                      <Link to={{ pathname: activeSettingResult.instagramLink }} target='_blank'>
                        <FontAwesomeIcon icon={faInstagram} size={35} style={{ fontSize: '24px' }} />
                      </Link>
                      :
                      null
                    }
                    {activeSettingResult.youtubeLink ?
                      <Link to={{ pathname: activeSettingResult.youtubeLink }} target='_blank'>
                        <FontAwesomeIcon icon={faYoutube} size={35} style={{ fontSize: '24px' }} />
                      </Link>
                      :
                      null
                    }
                  </div>
                </div>
                :
                null
            }
          </Col>
        </Row>
      </div>
      <div className='footer_text'>
        © 2021 Nirman Sewa. All Rights Reserved.
      </div>
    </div>
  );
}