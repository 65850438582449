import { isLocalHost, isDev } from "utils/util";

let config;
if (isLocalHost()) {
  //LOCAL config
  config = {
    assetURL: "http://localhost:8080/assets",
    graphURL: "http://localhost:8080/graphql",
    subscriptionURL: "ws://localhost:8080/subscriptions",
    cookieDomain: "localhost",
    // assetURL: 'https://api.dev.nirmangurunepal.com/assets',
    // graphURL: 'https://api.dev.nirmangurunepal.com/graphql',
    // subscriptionURL: 'wss://api.dev.nirmangurunepal.com/subscriptions'
  };
} else if (isDev()) {
  //DEV config
  config = {
    assetURL: "https://api.dev.nirmangurunepal.com/assets",
    graphURL: "https://api.dev.nirmangurunepal.com/graphql",
    subscriptionURL: "wss://api.dev.nirmangurunepal.com/subscriptions",
    cookieDomain: "dev.nirmangurunepal.com",
  };
} else {
  //PROD Config
  config = {
    assetURL: "https://api.nirmangurunepal.com/assets",
    graphURL: "https://api.nirmangurunepal.com/graphql",
    subscriptionURL: "wss://api.nirmangurunepal.com/subscriptions",
    cookieDomain: "nirmangurunepal.com",
  };
}

export default config;
