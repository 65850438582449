import { Link } from 'react-router-dom';
import { Button } from 'antd';

import routeConfig from 'Routes/config';
import config from 'configs/config';

import * as util from 'utils/util'

import './styles.scss';

export default ({ category, image, name, actualPrice, offerPrice, slug }) => {
  return (
    <div className='productcard'>
      <div className='productcard_image'>
        <img src={`${config.assetURL}/${image[0]}`} />
      </div>
      <div className='productcard_name'>
        {name}
      </div>
      <div className='productcard_price'>
        {offerPrice ?
          <div className='productcard_price_actual'>
            NRs {util.priceFormatter(actualPrice)}
          </div>
          :
          null}
        <div className='productcard_price_offer' style={{ paddingTop: offerPrice ? '5px' : '35px' }}>
          NRs {offerPrice ? util.priceFormatter(offerPrice) : util.priceFormatter(actualPrice)}
        </div>
      </div>
      <div className='productcard_button'>
        <Link to={routeConfig.productDetail.path.replace(':category', category).replace(':product', slug)}>
          <Button type='primary'>View Product</Button>
        </Link>
      </div>
    </div>
  );
}