import { useEffect, useState } from 'react';
import { Checkbox, Slider, Input, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

import useActiveBrandsWithProductCountByCategorySlug from 'hooks/brand/useActiveBrandsWithProductCountByCategorySlug';

import SpinnerLoader from 'components/Loader/SpinnerLoader';

import './styles.scss';

export default ({ urlCategory, totalProducts, filterParamsObject, applyFilter, clearFilter }) => {
  const [filterParams, setFilterParams] = useState({});

  useEffect(() => {
    if (filterParamsObject && Object.keys(filterParamsObject).length) {
      setFilterParams(filterParamsObject)
    } else {
      setFilterParams({})
    }
  }, [filterParamsObject])

  //active total product by category
  const { activeBrandsWithProductCountByCategorySlugResult, activeBrandsWithProductCountByCategorySlugLoading } = useActiveBrandsWithProductCountByCategorySlug(urlCategory);
  //active product price range by category
  const priceRange = {
    min: 1,
    max: 500000
  }

  const updateBrandFilter = (name, checked) => {
    let brandFilterArray = filterParams.brandfilter || [];

    if (checked) {
      if (!brandFilterArray.includes(name)) {
        brandFilterArray = [...brandFilterArray, name];
      }
    } else {
      brandFilterArray = brandFilterArray.filter(b => b !== name);
    }

    setFilterParams({ ...filterParams, brandfilter: brandFilterArray })
  };

  return (
    <div className='filtersidebar'>
      <div className='filtersidebar_title'>
        <div className='filtersidebar_title_text'>
          Narrow Your Search
        </div>
        <div className='filtersidebar_title_count'>
          ({totalProducts} items)
        </div>
      </div>
      <div className='filtersidebar_filter'>
        <div className='filtersidebar_filter_title'>
          <div className='filtersidebar_filter_title_text'>
            Brands
          </div>
          <div className='filtersidebar_filter_title_icon'>
            <FontAwesomeIcon icon={faChevronUp} />
          </div>
        </div>
        <div className='filtersidebar_filter_items'>
          {activeBrandsWithProductCountByCategorySlugLoading ?
            <SpinnerLoader />
            :
            activeBrandsWithProductCountByCategorySlugResult ?
              activeBrandsWithProductCountByCategorySlugResult.map(b =>
                <div key={b._id} className='filtersidebar_filter_items_single'>
                  <div className='filtersidebar_filter_items_single_checkbox'>
                    <Checkbox onChange={({ target }) => updateBrandFilter(b.slug, target.checked)} checked={filterParams && filterParams.brandfilter && filterParams.brandfilter.includes(b.slug)}>{b.title}</Checkbox>
                  </div>
                  <div className='filtersidebar_filter_items_single_count'>
                    ({b.productCount})
                  </div>
                </div>
              )
              :
              null
          }
        </div>
      </div>
      <div className='filtersidebar_pricefilter'>
        <div className='filtersidebar_pricefilter_title'>
          Price Range
        </div>
        <div className='filtersidebar_pricefilter_range'>
          <div className='filtersidebar_pricefilter_range_min'>
            Min: NRs {priceRange.min}
          </div>
          <div className='filtersidebar_pricefilter_range_max'>
            Max: NRs {priceRange.max}
          </div>
        </div>
        <div className='filtersidebar_pricefilter_slider'>
          <Slider
            range
            min={priceRange.min}
            max={priceRange.max}
            defaultValue={[priceRange.min, priceRange.max]}
            value={[filterParams.pricefilterstart || priceRange.min, filterParams.pricefilterend || priceRange.max]}
            onChange={(value) => setFilterParams({ ...filterParams, pricefilterstart: value[0], pricefilterend: value[1] })}
          />
        </div>
        <div className='filtersidebar_pricefilter_selectedrange'>
          <div className='filtersidebar_pricefilter_selectedrange_min'>
            <Input value={filterParams.pricefilterstart || priceRange.min} onChange={(event) => setFilterParams({ ...filterParams, pricefilterstart: parseInt(event.target.value) })} />
          </div>
          <div className='filtersidebar_pricefilter_selectedrange_max'>
            <Input value={filterParams.pricefilterend || priceRange.max} onChange={(event) => setFilterParams({ ...filterParams, pricefilterend: parseInt(event.target.value) })} />
          </div>
        </div>
        <div className='filtersidebar_pricefilter_button'>
          <Button type='default' onClick={() => clearFilter()}>
            Clear Filter
          </Button>
          <Button type='primary' onClick={() => applyFilter(filterParams)}>
            Apply Filter
          </Button>
        </div>
      </div>
    </div >
  );
}