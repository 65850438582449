import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { Row, Col } from 'antd'

import routeConfig from 'Routes/config';

import ProductCard from 'components/Card/ProductCard';

import './styles.scss';

const productSliderResponsiveSetting = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default ({ title, slug, list }) => {
  return (
    <div className='productslider'>
      <div className='productslider_title'>
        <Row>
          <Col span={slug ? 18 : 24}>
            {title}
          </Col>
          {slug ?
            <Col span={6}>
              <Link to={routeConfig.productList.path.replace(':category', slug)}>See All</Link>
            </Col>
            :
            null
          }
        </Row>
      </div>
      <div className='productslider_list'>
        <Carousel
          responsive={productSliderResponsiveSetting}
          infinite={true}
          slidesToScroll={1}
        >
          {list.map(l =>
            <div key={l._id}>
              <ProductCard category={l.category.slug} image={l.image} name={l.title} actualPrice={l.price} offerPrice={l.offerPrice} slug={l.slug} />
            </div>
          )}
        </Carousel>
      </div>
    </div>
  );
}