import { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Row, Col, Input, Select, Button } from "antd";

import routeConfig from "Routes/config";

import "./styles.scss";

export default ({}) => {
  const match = useRouteMatch();
  const history = useHistory();

  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(match.params.search ? match.params.search : "");
  }, [match.params.search]);

  const redirectToSearch = () =>
    history.push(
      routeConfig.productSearch.path.replace(":search", encodeURI(search))
    );

  return (
    <div className="searchbox">
      <Row align="middle">
        <Col span={12}>
          <div className="searchbox_input">
            <Input
              value={search}
              placeholder="I'm looking for"
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={(e) =>
                e.key === "Enter" || e.code === "Enter"
                  ? redirectToSearch()
                  : null
              }
            />
          </div>
        </Col>
        <Col>
          <div className="searchbox_select">
            <Select defaultValue="all">
              <Select.Option value="all">All</Select.Option>
            </Select>
          </div>
        </Col>
        <Col>
          <div className="searchbox_button">
            <Button type="primary" onClick={redirectToSearch}>
              Search
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
