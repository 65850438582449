import { BrowserRouter, Switch, Route } from 'react-router-dom';

import routeConfig from 'Routes/config';

import AuthRoute from 'Routes/AuthRoute';
import PrivateRoute from 'Routes/PrivateRoute';
import AppRoute from './AppRoute';

const ROUTE_TYPES = {
  'auth': (key, path, Component) => <AuthRoute key={key} exact path={path} component={Component} />,
  'private': (key, path, Component) => <PrivateRoute key={key} exact path={path} component={Component} />,
  'app': (key, path, Component) => <AppRoute key={key} exact path={path} component={Component} />,
  'default': (key, path, Component) => <Route key={key} exact path={path} render={Component} />
}

export default ({ }) => {
  return (
    <BrowserRouter>
      <Switch>
        {Object.keys(routeConfig).map(key => ROUTE_TYPES[routeConfig[key].type || 'default'](key, routeConfig[key].path, routeConfig[key].component))}
      </Switch>
    </BrowserRouter>
  );
}