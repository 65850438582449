import { useContext } from 'react';
import { Modal, Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import { SettingContext } from 'context/SettingContext';

import SpinnerLoader from 'components/Loader/SpinnerLoader';

import './styles.scss';

export default ({ visible, onClose }) => {
  const { activeSettingLoading, activeSettingResult } = useContext(SettingContext);

  return (
    <Modal visible={visible} onCancel={onClose} footer={null} wrapClassName='inquirycallmodal'>
      {activeSettingLoading ?
        <SpinnerLoader />
        :
        activeSettingResult ?
          <>
            <div className='inquirycallmodal_title'>
              Contact Phone:
            </div>
            <div className='inquirycallmodal_phone'>
              <Row>
                <Col md={12}>
                  {activeSettingResult.primaryPhone}
                </Col>
                <Col>
                  <a href={`tel:${activeSettingResult.primaryPhone}`}>
                    <FontAwesomeIcon icon={faPhoneAlt} />
                  </a>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col md={12}>
                  {activeSettingResult.secondaryPhone}
                </Col>
                <Col>
                  <a href={`tel:${activeSettingResult.secondaryPhone}`}>
                    <FontAwesomeIcon icon={faPhoneAlt} />
                  </a>
                </Col>
              </Row>
            </div>
          </>
          : null
      }
    </Modal>
  );
}