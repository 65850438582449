import Homepage from "pages/Homepage";
import ProductList from "pages/Product/List";
import ProductDetail from "pages/Product/Detail";
import AboutUs from "pages/AboutUs";
import PrivacyNotice from "pages/PrivacyNotice";
import TermsAndCondition from "pages/TermsAndCondition";
import ContactUs from "pages/ContactUs";
import Disclaimer from "pages/Disclaimer";

// Route types ['auth', 'private', 'app', 'default']
export default {
  homepage: {
    type: "app",
    path: "/",
    component: Homepage,
  },
  disclaimer: {
    type: "app",
    path: "/disclaimer",
    component: Disclaimer,
  },
  termsAndCondition: {
    type: "app",
    path: "/terms-and-condition",
    component: TermsAndCondition,
  },
  privacyNotice: {
    type: "app",
    path: "/privacy-notice",
    component: PrivacyNotice,
  },
  aboutUs: {
    type: "app",
    path: "/about-us",
    component: AboutUs,
  },
  contactUs: {
    type: "app",
    path: "/contact-us",
    component: ContactUs,
  },
  productList: {
    type: "app",
    path: "/:category",
    component: ProductList,
  },
  productSearch: {
    type: "app",
    path: "/search?query=:search",
    component: ProductList,
  },
  productDetail: {
    type: "app",
    path: "/:category/:product",
    component: ProductDetail,
  },
};
