import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  ActiveBrandsWithProductCountByCategorySlug($categorySlug: String!) {
    activeBrandsWithProductCountByCategorySlug(categorySlug: $categorySlug) {
      _id
      title
      slug
      image
      productCount
    }
  }
`;

export default (categorySlug) => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'activeBrandsWithProductCountByCategorySlug',
    variables: { categorySlug },
    fetchPolicy: 'cache-and-network',
    defaultValue: []
  });

  return { activeBrandsWithProductCountByCategorySlugResult: result, activeBrandsWithProductCountByCategorySlugLoading: loading, activeBrandsWithProductCountByCategorySlugError: error };
}