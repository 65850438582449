import { useContext, useEffect, useState } from 'react';
import { Row, Col, Form, Button, Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faEnvelope, faPhoneAlt, faClock } from '@fortawesome/free-solid-svg-icons'

import { SettingContext } from 'context/SettingContext';

import useContactMessageCreate from 'hooks/contactMessage/useContactMessageCreate';

import * as validation from 'utils/validation';

import TextInput from 'components/Input/TextInput';
import SpinnerLoader from 'components/Loader/SpinnerLoader';

import './styles.scss';

export default ({ }) => {
  const [form] = Form.useForm();

  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const { activeSettingLoading, activeSettingResult } = useContext(SettingContext);

  const { contactMessageCreateTrigger, contactMessageCreateLoading, contactMessageCreateResult } = useContactMessageCreate();
  const submitFormData = (formData) => {
    setSuccessMessage('')

    contactMessageCreateTrigger(formData);
  };

  useEffect(() => {
    if (!contactMessageCreateLoading) {
      if (contactMessageCreateResult && contactMessageCreateResult._id) {
        form.resetFields();

        setSuccessMessage('Message sent!')
      }
    }
  }, [contactMessageCreateLoading, contactMessageCreateResult]);

  return (
    <div className='contactus'>
      <div className='contactus_title'>
        Contact Us
      </div>
      <div className='contactus_body'>
        <Row gutter={[16, 16]}>
          <Col md={12} xs={24}>
            <div className='contactus_body_links_title'>
              Keep in touch with us for best pricing and deals
            </div>
            {activeSettingLoading ?
              <SpinnerLoader />
              :
              activeSettingResult ?
                <div className='contactus_body_links_items'>
                  <div className='contactus_body_links_items_single'>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> {activeSettingResult.address}
                  </div>
                  <div className='contactus_body_links_items_single'>
                    <FontAwesomeIcon icon={faEnvelope} /> {activeSettingResult.infoEmail}
                  </div>
                  <div className='contactus_body_links_items_single'>
                    <FontAwesomeIcon icon={faPhoneAlt} /> {activeSettingResult.primaryPhone}, {activeSettingResult.secondaryPhone}, {activeSettingResult.officePhone}
                  </div>
                  <div className='contactus_body_links_items_single'>
                    <FontAwesomeIcon icon={faClock} /> {activeSettingResult.officeTime}
                  </div>
                </div>
                :
                null
            }
          </Col>
          <Col md={10} xs={24} offset={{ md: 1, xs: 0 }}>
            <div className='contactus_body_form'>
              <div className='contactus_body_form_title'>
                Fill in the form for your query
              </div>
              <div className='contactus_body_form_form'>
                <Form
                  className="row-col"
                  layout="vertical"
                  form={form}
                  initialValues={{}}
                  onFinish={submitFormData}
                >
                  <Row gutter={[24, 0]}>
                    <Col span={24}>
                      <TextInput
                        label='Your Name'
                        name='name'
                        placeholder='Your Name'
                        rules={[
                          {
                            required: true,
                            message: "Please input name!",
                          }
                        ]}
                      />
                    </Col>
                    <Col span={24}>
                      <TextInput
                        label='Your Mobile Number'
                        name='mobile'
                        placeholder='Your Mobile Number'
                        rules={[
                          {
                            required: true,
                            message: "Please input mobile number!",
                          },
                          () => ({
                            validator(_, value) {
                              if (value && value !== '') {
                                if (!validation.mobile(value)) {
                                  return Promise.reject(new Error('Invalid mobile!'));
                                }
                              }
                              return Promise.resolve();
                            },
                          })
                        ]}
                      />
                    </Col>
                    <Col span={24}>
                      <TextInput
                        label='Your Message'
                        name='message'
                        placeholder='Your Message'
                        type='textarea'
                        rows={5}
                        rules={[
                          {
                            required: true,
                            message: "Please input message!",
                          }
                        ]}
                      />
                    </Col>
                    {successMessage != '' ?
                      <Col md={24}>
                        <Alert message={successMessage} type='success' style={{ marginBottom: 24 }} />
                      </Col>
                      :
                      null}
                    <Col md={24}>
                      <Form.Item wrapperCol={{ span: 12 }}>
                        <Button type="primary" htmlType="submit" loading={contactMessageCreateLoading} block>
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}