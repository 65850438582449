import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  ActiveProductBySlug($slug: String!) {
    activeProductBySlug(slug: $slug) {
      _id
      brand {
        _id
        title
      }
      title
      slug
      sku
      price
      offerPrice
      deliveryTime
      description
      overview
      image
      video
    }
  }
`;

export default (slug) => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'activeProductBySlug',
    variables: { slug },
    fetchPolicy: 'cache-and-network',
    defaultValue: {}
  });

  return { activeProductBySlugResult: result, activeProductBySlugLoading: loading, activeProductBySlugError: error };
}