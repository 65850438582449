import { useContext, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Row, Col, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faBars } from "@fortawesome/free-solid-svg-icons";

import { SettingContext } from "context/SettingContext";
import { CategoryContext } from "context/CategoryContext";

import routeConfig from "Routes/config";

import config from "configs/config";

import SearchBox from "./SearchBox";

import SpinnerLoader from "components/Loader/SpinnerLoader";

import "./styles.scss";

export default ({}) => {
  const params = useParams();
  const history = useHistory();

  const [drawerVisible, setDrawerVisible] = useState(false);

  const { activeSettingLoading, activeSettingResult } =
    useContext(SettingContext);
  const { activeCategoriesLoading, activeCategoriesResult } =
    useContext(CategoryContext);

  const redirectToPage = (link) => {
    setDrawerVisible(false);
    history.push(link);
  };

  return (
    <div className="header">
      {activeSettingLoading ? (
        <SpinnerLoader />
      ) : activeSettingResult ? (
        <div className="header_top">
          <Row>
            <Col md={0} xs={4}>
              <div className="header_top_hamburger">
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={() => setDrawerVisible(true)}
                />
              </div>
            </Col>
            <Col md={6} xs={6}>
              <div className="header_top_logo">
                <Link to={routeConfig.homepage.path}>
                  <div className="header_top_logo_image">
                    <img
                      src={`${config.assetURL}/${activeSettingResult.logo}`}
                      alt="logo"
                    />
                  </div>
                  {/* <div className='header_top_logo_text'>
                      <div className='header_top_logo_text_top'>
                        Nirman Guru
                      </div>
                      <div className='header_top_logo_text_bottom'>
                        Nepal
                      </div>
                    </div> */}
                </Link>
              </div>
            </Col>
            <Col md={12} xs={0}>
              <div className="header_top_search">
                <SearchBox />
              </div>
            </Col>
            <Col md={6} xs={14}>
              <div className="header_top_contact">
                <div className="header_top_contact_text">
                  Available {activeSettingResult.officeTime} at
                </div>
                <div className="header_top_contact_number">
                  <FontAwesomeIcon icon={faPhoneAlt} />{" "}
                  {activeSettingResult.primaryPhone},{" "}
                  {activeSettingResult.secondaryPhone}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : null}

      {activeCategoriesLoading && activeCategoriesResult.length === 0 ? (
        <SpinnerLoader />
      ) : activeCategoriesResult ? (
        <div className="header_nav">
          <Row justify="space-between">
            {activeCategoriesResult.map((c, i) => (
              <Col key={i}>
                <Link
                  to={routeConfig.productList.path.replace(":category", c.slug)}
                >
                  <div
                    className={`header_nav_item ${
                      c.slug === params.category ? "active" : ""
                    }`}
                  >
                    {c.title}
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      ) : null}

      <Drawer
        placement="left"
        closable={false}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
      >
        <div className="header_drawer">
          <Row>
            <Col>
              <div className="header_drawer_search">
                <SearchBox />
              </div>
            </Col>
          </Row>
          <div className="header_drawer_nav">
            <Row gutter={[12, 12]}>
              {activeCategoriesResult.map((c, i) => (
                <Col key={i} span={24}>
                  <div
                    className={`header_drawer_nav_item ${
                      c.slug === params.category ? "active" : ""
                    }`}
                    onClick={() =>
                      redirectToPage(
                        routeConfig.productList.path.replace(
                          ":category",
                          c.slug
                        )
                      )
                    }
                  >
                    {c.title}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
