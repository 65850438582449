import { createContext, useState, useEffect } from "react";

import useActiveCategories from "hooks/category/useActiveCategories";

const initialCategories = [];

export const CategoryContext = createContext({ activeCategoriesLoading: true, activeCategoriesResult: initialCategories });

export const CategoryProvider = ({ children }) => {
  const [categories, setCategories] = useState(initialCategories);

  const { activeCategoriesLoading, activeCategoriesResult } = useActiveCategories();

  useEffect(() => {
    if (!activeCategoriesLoading && activeCategoriesResult) {
      if (categories.length == 0) {
        setCategories(activeCategoriesResult);
      }
    }
  }, [activeCategoriesLoading, activeCategoriesResult])

  return (
    <CategoryContext.Provider value={{ activeCategoriesLoading: activeCategoriesLoading, activeCategoriesResult: categories }}>
      {children}
    </CategoryContext.Provider>
  );
}