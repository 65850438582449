import { useEffect } from "react";

import "./styles.scss";

export default ({}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="termsandcondition">
      <div className="termsandcondition_title">Terms and Condition</div>
      <div className="termsandcondition_body">
        {/* <p>
          In compliance with the Privacy Policies, the terms governing your purchase
          and use of nirman guru nepal products and services are as follows and
          constitute between you a legally binding agreement and nirman guru nepal.
        </p>
        <p>
          For these terms and conditions of service, wherever the term ‘you/user’
          comes in shall mean any natural or legal individual who has downloaded and
          installed this mobile application (‘referred to as app’) and registration
          data provided during application registration in the app or by visiting the
          website by entering the domain name in any web browser. Any services
          provided by the website/app which do not require registration does not
          exclude you from the contractual relationship laid down in this agreement.
        </p>
        <p>
          Wherever the situation demands, anybody authorized and related to the
          construction sector shall be referred to as an expert/registered expert who
          will be verified and authenticated by us.
        </p>
        <p>
          You are bound by these Service Terms and any other contractual agreement as
          determined by the Company when you download and install this app. Before
          acknowledging this, you are requested to read them carefully and continue
          to use the website/app.
        </p>
        <p>
          When you visit, view, connect or otherwise use any of the services or
          information created, collected, compiled, or submitted to
          www.nirmangurunepal.com, all following sale/service terms and conditions
          will be deemed to have been agreed upon. Please read these Terms carefully
          before using this website and app, because you agree to be bound by them.
          Please do not use this website/app if you do not agree with these Terms and
          Conditions.
        </p>
        <p>
          1. <strong>PRIVACY POLICY:</strong>
        </p>
        <ul>
          <li>
            In accordance with our Privacy Policy, your personal identifiable
            information will be used. Refer to our Privacy Policy to understand our
            practice of collecting, utilizing and disclosing your personal
            information, which governs your www.nirmangurunepal.com visit as well.
          </li>
          <li>
            We understand that the privacy of all the information you provide is
            essential. We will do everything we can to make a cautious and
            sensitive use of it. Such information is never exchanged with other
            businesses or third party service providers.
          </li>
        </ul>
        <p>
          2. <strong>COMMUNICATIONS:</strong>
        </p>
        <ul>
          <li>
            By using this website/app it is deemed that you have approved the use
            of the telephone number given by the user, according to the Privacy
            Policy, to receive auto-dialed or pre-recorded messages or calls at any
            time from us. This includes interacting with you via information
            received from other parties and contacting other parties with
            information which you provide to us. You also agree to receive SMSs
            from us whenever we deem fit by using this website/app. This consent is
            for reasons which include clarification calls, advertisement and
            promotional calls and are not limited to. If you wish us to stop
            contacting you, you can email us at info@nirmangurunepal.com
          </li>
          <li>
            In furtherance of our rights, responsibilities, and obligations in
            compliance with other arrangements, contracts and procedures adopted by
            us, you may also be approached by service providers with whom we have
            entered into a contract. Such communication will only be made,
            according to the respective contracts, agreements and/or policies.
          </li>
          <li>
            The information exchanged by you shall be subject to the Privacy
            Policy. Your contact information shall not be revealed to third parties
            not connected to the website/app, to the services offered or for any
            reason not related to the company's activities. For additional
            information refer to the 'Privacy Policy'.
          </li>
        </ul>
        <p>
          3. <strong>SECURITY POLICY:</strong>
        </p>
        <p>
          All transactions in www.nirmangurunepal.com are safe when ordering online.
          The 256 bit Secure Sockets Layer (SSL) encryption technology is used to
          encrypt your personal information, before it has been transmitted over the
          Internet, which ensures confidentiality and high security levels.
        </p>
        <p>
          4. <strong>CUSTOMER MEMBERSHIP/USAGE/ELIGIBILITY:</strong>
        </p>
        <ul>
          <li>
            The website/app membership/use is limited to those older than 18 years
            old, or for a group that could be called the 'majority' according to
            the law of the respective jurisdictions where this website/app can be
            seen and is open to or independent minors, who has legal consent of
            parent or guardian, or are in a position to enter into the terms,
            conditions, commitments, affirmations, representations and assurances
            set out in the following terms and conditions of service thoroughly and
            competently, and to accept these Terms of Service and to comply with
            them.
          </li>
          <li>
            You agree that the app you download/or the website you use on your
            phone or tablet is our property and the website/app may be updated
            automatically, and these terms also apply in respect to the updates. We
            reserve the right without notice to terminate this agreement and
            without any reason suspend the account.
          </li>
        </ul>
        <p>
          5. <strong>PRICE AND PAYMENT:</strong>
        </p>
        <ul>
          <li>
            The website/app can be installed and used at no charge and it includes
            only the use of certain website/ app features. You agree that, at the
            discretion of the company, this no charging policy may be changed at
            any time. Nevertheless, you are required to pay for consultation if you
            use the website/app to choose an expert/registered expert.
          </li>
          <li>
            For this reason, any user who opts for the services offered on our
            website/app would be included as a buyer. Seller means
            Us/Website/App/Company.
          </li>
          <li>
            When using any payment methods accessible through the website/app, we
            shall not assume any obligation or liability whatsoever, in relation to
            any loss or damage, either directly or indirectly to you due to:
          </li>
        </ul>
        <ul type="disc">
          <li>
            Missing of any transaction(s) authorization, or
          </li>
          <li>
            Exceeding the mutually agreed preset cap between you and 'Bank(s)', or
          </li>
          <li>
            Any payment difficulties during transaction, or
          </li>
          <li>
            Denial of transaction for any other reason(s).
          </li>
        </ul>
        <ul>
          <li>
            You shall be obliged to pay for the services you render in Nepalese
            Rupees on the website/app. The website/app will not entertain
            transactions in any other form of currency with respect to purchases
            made on website/app.
          </li>
          <li>
            The credit limit to be provided to the users shall be governed by
            separate policy or contract.
          </li>
          <li>
            The product prices in the app/website are        <strong>inclusive of all applicable taxes</strong>.
          </li>
          <li>
            We do our utmost to ensure that items prices and availability on our
            website/app are updated. In rare circumstances, however, product prices
            error or a product availability error may occur. We are not liable for
            any typographical errors in these circumstances and reserve the right
            to cancel the transaction.
          </li>
          <li>
            6.8 We reserve the right to rectify pricing and product
            availability/description inaccuracies or omissions, even after the
            order has been submitted, and at any time without prior notice to
            change or update any other information.
          </li>
          <li>
            Delivery times can vary depending on the place of delivery and the type
            of product you order.
          </li>
          <li>
            The payment can be made by any of the following payment methods as per
            availability:
            <br />
            Credit/Debit Card or Cash on Delivery or Online Wallets., POS, QR, IPS,
            cheque
          </li>
        </ul>
        <p>
          6. <strong>USE OF INFORMATION ON THE WEBSITE/APP:</strong>
        </p>
        <p>
          No viewpoints or views included in the website or app content is supported
          by www.nirmangurunepal.com, or other information that our services offer.
          Sending any request or query about our products does not create a
          professional relationship between you and www.nirmangurunepal.com.
        </p>
        <p>
          7. <strong>THIRD PARTY INFORMATION:</strong>
        </p>
        <ul>
          <li>
            Our content includes the content of the website/app, which includes all
            text, graphics, UIs, visual interfaces, images, trademarks, logos,
            sounds, music, design documents and artwork (hereinafter referred to as
            ‘Content’). All links connecting to third party content available in
            the website/app might not be associated with us. We are merely an
            intermediary of those content and we do not have any control over
            contents created by a third party. If any of the Third Party Content
            violates another person's intellectual property, any loss caused is the
            sole responsibility of the Third Party and we're not responsible. To
            report any such material, you can send an e-mail to
            info@nirmangurunepal.com.
          </li>
          <li>
            Except if expressly permitted, any use and duplication, republishing,
            downloading, writing, publicly showing, encoding, translating,
            transmitting or distributing of our Content (including "mirroring"), to
            any other publication or distribution medium, server, website or other
            platform or any commercial company, is not permissible without our
            express prior written consent.
          </li>
        </ul>
        <p>
          8. <strong>USER CONTENT:</strong>
        </p>
        <ul>
          <li>
            Contents uploaded or posted will become our property and grant us
            rights that are universal, permanent and transferable. We have the
            right to, in compliance with the applicable law of our Privacy Policy,
            use the Content or any of its components forever for any kind of using,
            for promotional and advertisement purposes, but not limited to and in
            any media currently known or later conceived, including the production
            of derivatives which may contain any material you provide and no
            payment or fee shall be provided for such use. You also authorize us to
            grant these rights to be sub-licensed and to take action if these
            rights are breached. We reserve the right of modifying or deleting
            material posted on or saved on this website/app or storing or
            publishing it on our servers.
          </li>
          <li>
            Those who add any user content to the website/app have to check that
            the content‘s information are valid and authentic, time, place and
            nature included. Before such material is made available on the
            website/app, we have the right and authority to check the accuracy of
            such data related to the content with the respective sources provided
            by the user, if we think the accuracy needs to be monitored. We have
            the right, after collecting and verifying the information, to upload
            content on behalf of third parties. Nevertheless, for any false or
            misleading material, we cannot in any way be held responsible.
          </li>
          <li>
            If any content in any jurisdiction in which the website/app can be
            viewed and accessed is deemed to be unlawfully or contrary to law, we
            shall initiate the process of required action to delete it, when the
            authorities in the respective competence inform us that this material
            is considered illegal. We cannot accept responsibility or be questioned
            for it.
          </li>
        </ul>
        <p>
          9. <strong>USER OBLIGATIONS:</strong>
        </p>
        <p>
          You are a restricted user of this website/app.
        </p>
        <ul>
          <li>
            You may not use any information or software from the website/app, copy,
            distribute, alter, reverse engineer, disseminate, upload, publish or
            create derivative work. Limited use may be permitted with our prior
            permission. To resolve any doubt, it has been explained that it is not
            allowed to reproduce unlimited or wholesale material, copy content for
            commercial or non-commercial purposes and to change data and
            information in the sense of the content of the website/app. You are not
            permitted or allowed on behalf of third parties to (i) make copies of
            the website/app and distribute them (ii) try copying, reproducing,
            updating, reverse engineering, dismounting, decompiling or translating
            the website/app; (iii) build derivative works of any kind of the
            website/app.
          </li>
          <li>
            You accept that you will not access (or attempt to access) the
            website/app and/or the materials or resources by any means other than
            through the website/app. Use of deep-link, robot, spider or other
            automated tool, software, algorithm or methodology, or any manual
            process similar or equivalent, to access, gain, copy or track any part
            of the App or Content, or reproduce or circumvent in any way the
            navigational structure or presentation of the website/app, materials or
            any content, to obtain or attempt to obtain materials, records or
            information by any means not specifically made available via the
            website/app. Through accessing or using the website/app or products,
            you accept and consent, you may be exposed to other users' content that
            you may find offensive, inappropriate or otherwise unacceptable. On the
            website/app, we disclaim all liabilities arising from such offensive
            content. However, by sending us an e-mail to our official e-mail
            address you can report such offensive content.
          </li>
          <li>
            By nature, this website/app enables you to upload information; you
            undertake to ensure that the content complies with applicable laws. In
            addition, you agree not to:
          </li>
        </ul>
        <p>
          o Abuse, harass, threat, slander, disillusion, erode, abrogate, demean or
          otherwise violate other people's legal rights;
        </p>
        <p>
          o Engage in any operation that interferes with or disrupts access to the
          website/app or the Services (or the website/app servers and networks);
        </p>
        <p>
          o Impersonate another person or entity or state your association with a
          person or falsely or otherwise misrepresent;
        </p>
        <p>
          o Post any file that infringes other legal entities' copyright, patent or
          trademark.
        </p>
        <p>
          o Upload or distribute files containing viruses, corrupted data, or any
          other similar software or programs that could damage the activity of the
          website/app or the computer of another;
        </p>
        <p>
          o Download any file posted by another user of a Service that you know, or
          should fairly know, cannot be distributed lawfully in this way;
        </p>
        <p>
          o Investigate, scan or test the website/app vulnerability or any network
          connected to the website/app, or infringe website/app security or
          authentication measures or any network connected to the website/app. You
          may not reverse check, monitor or attempt to find any details of any other
          user, or visitor, or any other customer to the website/app, including any
          account you do not own, at its source, or exploit the website/app or
          service or data made available or offered by or through the website/app,
          whether the intention is to disclose any information or not, including
          personal identification details, but not limited to, as given by the
          website/app, other than your own information;
        </p>
        <p>
          o Disrupt or impair with the security of, or else it is harmful to the
          website/app, system resources, accounts, passwords, servers or linked
          networks or accessible via the website/app or any related or associated
          websites;
        </p>
        <p>
          o Collect or store other user data relevant to the forbidden behavior and
          behaviors set out in this section.
        </p>
        <p>
          o Use the website/app or any material or content for any reason that is
          unlawful or prohibited by these Terms of Service or request that any
          illegal activity or other action that infringes the rights of this
          website/app or other third parties be carried out;
        </p>
        <p>
          o Violate any code of conduct or other rules that may refer to or on behalf
          of any particular Service;
        </p>
        <p>
          o Violate for the time being any relevant laws or regulations in or outside
          Nepal;
        </p>
        <p>
          o Violate the Terms of Service, including but not limited to any other
          relevant website/app terms found herein or anywhere else;
        </p>
        <p>
          o Violate any code of conduct or other rules specific to or applicable to
          any Service;
        </p>
        <p>
          o Threaten Nepal’s unity, independence, protection, security, or
          sovereignty, friendly relationships with foreign states, or public order,
          or cause incitement to commit any known offense, or prevent investigation
          of any offense, or insult any other country.
        </p>
        <p>
          o Publish, post, disseminate false, inaccurate or misleading information;
          violate any laws or regulations in place in or outside Nepal for the time
          being;
        </p>
        <p>
          o Directly or indirectly, sell, attempt to offer, trade or attempt to trade
          in any item which, for the time being, is prohibited or limited in any way
          under any applicable law, rule, regulation or guideline.
        </p>
        <p>
          o Creating liability for us or causing us to lose (in whole or in part) our
          Internet service provider's services ("ISPs") or other providers;
        </p>
        <ul>
          <li>
            While we are not liable or accountable for any such actions on your
            part to the Users, violation of this provision would result in possible
            criminal charges against you, either from other website/app users or
            from us.
          </li>
          <li>
            We may (and hereby expressly authorize) disclose any information about
            you to law enforcement officials or other government officials, as we,
            in our sole discretion, consider it necessary or appropriate to
            investigate and/or resolve possible crimes, in particular, those that
            may involve personal injury. You understand that we have the right to
            disclose any information at all times (including identification of the
            individuals’ providing details or content on the website/app) as
            necessary to comply with any applicable law, regulation or government
            request. This may include, but is not limited to, the disclosure of
            information in connection with the investigation of alleged illegal
            activity or request for illegal activity or in response to a lawful
            order of the court.
          </li>
          <li>
            10.6 We do not have an obligation to monitor the material posted on the
            website/app. We shall have the right to remove or modify any content
            that is in breach of its sole discretion, or it is alleged that it
            infringes any applicable law or the spirit or letter of these Terms of
            Service. Given this right, you just consent to the MATERIAL CONTENT you
            are publishing in the website/app. We shall not presume in any
            situation or be accountable or liable for any posted content or for any
            claims, damages or injuries resulting from content use and/or Content
            presence on the App. You hereby reflect and guarantee that all rights
            in and to all content that you provide and the details it includes and
            that no proprietary or other rights of third parties are breached by
            such Content or contains any information that is defamatory, tortuous,
            or otherwise illegal.
          </li>
        </ul>
        <p>
          10. <strong>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY:</strong>
        </p>
        <ul>
          <li>
            All of the content, products or services that are included in this
            website/app are made available to you by www.nirmangurunepal.com on an
            “as is” and “as available” basis, either expressed or implicated, in
            particular, in the degree permitted by law applicable we preclude
            warranties of any kind. You expressly consent to be solely responsible
            for your use of this website/app.
          </li>
          <li>
            www.nirmangurunepal.com shall not take responsibility for any losses or
            viruses caused by access, use or browsing on this site that may corrupt
            your device or other property.
          </li>
          <li>
            www.nirmangurunepal.com has made reasonable efforts to ensure that
            information published at the time of publishing is correct;
            nevertheless, we shall not be liable for errors in such records. We
            reserve the right in our sole discretion to delete or change any
            information contained on the website/app.
          </li>
          <li>
            The adequacy, currency or comprehensiveness of website/app content
            cannot be guaranteed by www.nirmangurunepal.com. The reliability,
            consistency, or protection of products available on the website/app is
            not assured or endorsed by www.nirmangurunepal.com.
          </li>
          <li>
            We disclaim all liability for any injury to individuals arising from
            the guidance or items specified on the website/app. No supplier of any
            products or service on the website/app is affiliated with
            www.nirmangurunepal.com. We do not guarantee that your requirements
            will be met by the website/app or its content.
          </li>
          <li>
            Please notice that while nirman guru nepal has tried hard to show the
            colors of the products correctly on their website/app, your monitor
            will define on the actual color you see. We cannot ensure that the
            color display of your monitors is identical to that of your delivered
            products.
          </li>
          <li>
            We can allow you to view and interact with us through the services of
            social media such as Facebook and Instagram. nirman guru nepal
            expressly disclaims all liability for the terms and conditions of use
            and the rules regulating the web pages of such third parties which are
            not affiliated in any way with us.
          </li>
          <li>
            www.nirmangurunepal.com does not take any responsibility for damages or
            injuries sustained because you rely on nirman guru nepal users' product
            reviews.
          </li>
          <li>
            www.nirmangurunepal.com reserves the right at any moment, without
            warning, to change or delete any aspect of the website/app or its
            contents.
          </li>
        </ul>
        <p>
          11. <strong>APP/WEBSITE AVAILABILITY:</strong>
        </p>
        <p>
          We are careful to ensure www.nirmangurunepal.com website/app is available
          24 hours a day, 365 days a year. However, because of maintenance, server or
          other technical problems, or for reasons beyond our control, the
          website/app may become temporarily unavailable. www.nirmangurunepal.com
          does not guarantee uninterrupted access to this website/app or any site/app
          linked. However, if we know about the planned maintenance of our
          website/app, we can issue a notice, but are not obliged to do so.
        </p>
        <p>
          12. <strong>LINKS TO THIRD-PARTY WEBSITES:</strong>
        </p>
        <p>
          Our online payment processing providers connect to third-parties websites,
          which manage your Mobile Banking and Credit / Debit Card payments. We are
          not responsible for whether these connected pages or sites are available or
          functioning properly and no liability for loss or disadvantages resulting
          from the use of such website link shall be held to us.
        </p>
        <p>
          13. <strong>TERMS OF SALE:</strong>
        </p>
        <ul>
          <li>
            www.nirmangurunepal.com may, at its absolute discretion, approve or
            reject any order placed by a customer without liability.
          </li>
          <li>
            www.nirmangurunepal.com reserves the right on its website/app to
            discontinue any plan or offer.
          </li>
          <li>
            The order quantity of any product available on www.nirmangurunepal.com
            can be restricted without notice.
          </li>
          <li>
            We acknowledge and agree that you have accurately divulged your
            personal details and information and consent for its use by nirman guru
            nepal and/or its affiliated companies.
          </li>
          <li>
            You approve and nominate nirman guru nepal and/or its employees if you
            are the customer/ parent/authorized on behalf of the customer, to take
            all steps, sign all documents as your lawyer and agent and act on
            behalf of you as if for limited purposes you were personally present
            and acting for yourself. You hereby allow nirman guru nepal and/or our
            associates to deliver the order to your designated place via
            post/courier. This authorization includes but is not limited to the
            processing and use of personal and confidential information as required
            for the execution of your request. “YOU HAVE READ AND UNDERSTOOD THESE
            TERMS AND CONDITIONS AND ACCEPT THAT THEY ARE ASSOCIATED WITH YOURSELF
            AND YOUR ASSIGNS, HEIRS AND PEERS.”
          </li>
        </ul>
        <p>
          14. <strong>CANCELLATION, RETURN AND REFUND POLICY:</strong>
        </p>
        <p>
          <strong>Return Policy:</strong>
        </p>
        <p>
          We make every effort to ensure that your goods are delivered to your
          specifications. If you receive an incomplete, damaged, defective or
          incorrect order at the time of delivery, to ensure prompt resolution, you
          can immediately request for return/replacement or file a return/replacement
          request on the app or website or mail us within 7 working days of the date
          of delivery. Please note that if you do not contact us within 7 working
          days of delivery, www.nirmangurunepal.com shall accept no responsibility
          for such delivery problems.
        </p>
        <p>
          The product(s) must include the original tags, user manual, warranty cards,
          freebies and accessories. The product(s) must be returned in the original
          and undamaged manufacturer packaging / box. Do not put tape or stickers on
          the manufacturers box.
        </p>
        <p>
          Please permit us 7 to 15 working days from the date of receipt of the
          product or products along with return request to entertain your request.
        </p>
        <p>
          Shipping cost for returning the product shall be borne and incurred by the
          buyer.
        </p>
        <p>
          <strong>Return Policy Exceptions:</strong>
        </p>
        <p>
          Please note that for certain product categories (as determined by the
          company), we cannot provide substitutes or exchanges.
        </p>
        <p>
          www.nirmangurunepal.com reserves the right, as indicated on the respective
          product pages, to deny returns (or refunds) for certain products, which is
          marked as "Note: This item cannot be returned for a refund or exchange."
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>Refund Policy:</strong>
        </p>
        <p>
          We at www.nirmangurunepal.com make sure you are entirely satisfied with our
          products. We do our best and on the basis of the following conditions, we
          will gladly grant a full refund:
        </p>
        <p>
          <strong>Possible full refund if:</strong>
        </p>
        <ul>
          <li>
            you got a faulty item;
          </li>
          <li>
            during transit the item(s) ordered are lost or damaged;
          </li>
          <li>
            the ordered item(s) is past its expiry date.
          </li>
        </ul>
        <p>
          <strong>How to Request a Refund:</strong>
        </p>
        <p>
          For a refund, simply file a refund request on the app or website or mail us
          within 7 working days of the date of delivery including why you request a
          refund. We take customer feedback seriously and use it for the continuous
          improvement of our service quality. Please note that if you do not contact
          us within 7 working days of delivery, www.nirmangurunepal.com shall accept
          no responsibility for such refund request.
        </p>
        <p>
          Please permit us 15 to 30 working days from the date of receipt of the
          product or products along with refund request to entertain your request.
          While we regret any inconvenience caused by this timeframe, the policy of
          the bank is that it affects the refund timetable, and we have no influence
          over it. Credit should be issued once the entire clarities will be removed,
          if the refund mode is by cash on delivery.
        </p>
        <p>
          Except for Cash On Delivery transaction, refund, if any, shall be made at
          the same Issuing Bank from where transaction price was received, or through
          refund voucher, cheque or online banking / electronic funds transfer
          approved by Nepal Rastra Bank (NRB), or through any other method available
          on the platform, as chosen by you.
        </p>
        <p>
          For Cash On Delivery transactions, refunds, if any, will be made via refund
          voucher, Cheque or online banking/electronic funds transfer as the case may
          be.
        </p>
        <p>
          Refund shall be made in Nepalese Rupees only and shall be equivalent to the
          Transaction Price received after deduction of charges, if applicable, in
          Nepalese Rupees.
        </p>
        <p>
          Refunds may be supported for select banks. Where a bank is not supported
          for processing refunds, you will be required to share alternate bank
          account details with us for processing the refund.
        </p>
        <p>
          The product(s) must include the original tags, user manual, warranty cards,
          freebies and accessories. The product(s) must be returned in the original
          and undamaged manufacturer packaging / box. Do not put tape or stickers on
          the manufacturers box. We may also request you for additional documents for
          verification.
        </p>
        <p>
          Shipping cost for returning the product(s) shall be borne and incurred by
          the buyer.
        </p>
        <p>
          For questions, please contact our Helpdesk at 01-5919966, email us at
          info@nirmangurunepal.com or chat online with our Customer Service Managers.
        </p>
        <p>
          15. <strong>CANCELLATION:</strong>
        </p>
        <p>
          The website/app is only a link between the customer and the vendor or
          expert for the service that has been booked. Once the order is placed and
          the individual has obtained a confirmation, the company shall inform the
          vendor or expert/registered expert who will render the service at the
          established time, location and mode provided.
        </p>
        <p>
          No amount shall be refunded once an order has been made and confirmed at
          our end in the event of cancellation. In some circumstances, however, the
          company shall allow the full or partial refund, at its discretion if the
          item or service is not available. The Company's decision in these cases is
          final.
        </p>
        <p>
          16.
          <strong>
            COPYRIGHTS, TRADEMARKS AND OTHER INTELLECTUAL PROPERTY RIGHTS:
          </strong>
        </p>
        <ul>
          <li>
            The website/app grants users/customers access to view the contents only
            for, visits, orders and communications as required by the Terms and
            Conditions of www.nirmangurunepal.com. The copyright and/or other
            intellectual rights shall be applicable to all materials in this
            website/app, including, but not limited to, images, illustrations,
            texts, logos and page headers included in this website/app. All other
            non-www.nirmangurunepal.com trademarks on this website/app are the
            property of their owners respectively, who may or may not be affiliated
            with, connected with, or sponsored by www.nirmangurunepal.com.
          </li>
          <li>
            You accept hereby that the content of www.nirmangurunepal.com will not
            be replicated, duplicated or copied for any reason, except in a
            separate agreement with this website/app you were specifically allowed
            to do so.
          </li>
        </ul>
        <p>
          17. <strong>USER INDEMNIFICATION:</strong>
        </p>
        <p>
          You agree to compensate, defend, and hold www.nirmangurunepal.com harmless
          and its officers, administrators, staff, agents, providers of information,
          partners, licensors, advertisers, suppliers, experts from and against all
          claims, damages, costs, reimbursement and expenditures, including fair
          legal fees and expenses, as a consequence of any violation of this
          agreement, or any activity connected with your account, or any material or
          information that you submitted or are going to submit to us in violation of
          any rule or in violation of any rights of third parties (includes but does
          not restrict, defamation allegations claims, breach of privacy, or any
          other intellectual property rights violation). We reserve the right to
          defend and control exclusively all claims arising from the above and any
          questions of such reimbursement, and hereby you agree to cooperate fully
          with us to uphold any defense necessary.
        </p>
        <p>
          18. <strong>APPLICABLE LAW AND JURISDICTION:</strong>
        </p>
        <p>
          These Terms and Conditions and your use of this website/app and its content
          shall, in accordance with the laws of Nepal governed by Nepal’s courts of
          jurisdiction, be governed and construed in all respects.
        </p>
        <p>
          19. <strong>DISPUTE RESOLUTION:</strong>
        </p>
        <p>
          These terms and conditions of service, without regard for their conflict of
          law clauses, are controlled and read in accordance with Nepalese laws. The
          place and court of dispute settlement, arbitration and court proceedings
          shall be in Nepal. The failure to comply with or exercise the clause of
          this Agreement or similar rights on the website/app/company shall not
          constitute a waiver of such right or provision. The section titles used in
          this Agreement are solely for convenience and shall have no legal or
          contractual effect. Should this Agreement be terminated for any cause, you
          consent to agree as follows: provisions concerning your use of content
          limitations, the website/app/company license(s) you have issued, and any
          other conditions that are equitable or necessary to survive.
        </p>
        <p>
          In the event that such conditions clash and the terms of any readable
          declaration or procedure, electronic or machine, these Service Terms shall
          take precedence. Likewise, these Terms of Service shall prevail in the
          event of disagreement between these terms and our Privacy Policy.
        </p>
        <p>
          A two-step Alternate Dispute Resolution ("ADR") mechanism will resolve all
          disputes that involve but cannot be limited to rights, compensation,
          refunds or other claims. The parties agree further that, even after
          termination or expiry of the Terms and/or Policy, the contents of this
          Section shall continue.
        </p>
        <ul type="disc">
          <li>
            Mediation: In the event of any dispute between the parties, the parties
            will try to settle amongst themselves peaceful, where both parties are
            equally satisfied. If within thirty (30) days of one party
            communicating dispute to the other party cannot reach a friendly
            solution, as stated herein, the conflict shall be resolved by
            arbitration:
          </li>
          <li>
            Arbitration: If the parties are not in a position to settle a conflict
            through mediation, that dispute shall, by a sole arbitrator to be
            appointed by the Company, be referred to arbitration, and both parties
            shall be valid and binding upon the award given by such single
            arbitrator. For the trials, the parties are responsible for their own
            expenses, while in his sole discretion the sole arbitrator may,
            instruct any one party to bear the full costs of the proceedings. The
            arbitration shall take place in English, and the city of Kathmandu,
            Nepal, will be the site of Arbitration. The parties agree clearly that
            the terms and conditions, Policy and other agreements between them are
            controlled by Nepal’s laws, rules and regulations, and that exclusive
            jurisdiction for any dispute between the parties shall lie with the
            courts at Kathmandu.
          </li>
        </ul>
        <p>
          20. <strong>SEVERABILITY:</strong>
        </p>
        <p>
          Where any of these Terms and Conditions is unconstitutional, invalid or
          unenforceable for any cause, that clause is then considered separable from
          these Terms and Conditions, the validity and enforceability of any other
          provisions shall not be affected.
        </p>
        <p>
          21. <strong>DISCLAIMER OF WARRANTIES AND LIABILITIES</strong>
        </p>
        <p>
          You explicitly understand and agree that as far as the applicable law
          allows:
        </p>
        <p>
          The website/app, resources and other contents offered are on an “as is”
          basis written, implied, contractual or otherwise, without any guarantee of
          any kind, including the conditional title warranties, for a specific
          purpose, non-infringement, marketability or fitness. We make no guarantee
          that, without limiting the foregoing:
        </p>
        <ul type="disc">
          <li>
            Your requirements will be fulfilled, or services provided are
            continuous, timely, reliable, or error-free;
          </li>
          <li>
            The materials, data and results obtained will be effective, exact or
            reliable;
          </li>
          <li>
            Any website/app errors, services or other material errors or defects
            shall be rectified
          </li>
          <li>
            As far as the applicable law permits, we shall not be responsible for
            the content of users arising from intellectual property rights,
            defamation, anonymity, advertisement, obscenity or any other laws.
            However, we shall not be held responsible for any misuse, loss,
            alteration or non-availability of any user content.
          </li>
        </ul>
        <p>
          The user understands and consent to the complete freedom and risks of any
          information or data that is downloaded or otherwise accessed via the
          website/app and are solely liable for any damage to their computer systems
          or loss of data resulting from such material or data download. No
          typographical error that leads to an invalid coupon will be held
          responsible by us. With respect to any information provided to you, whether
          on your own behalf or in the name of a third party, we assume no
          responsibility for any errors or omissions.
        </p>
        <p>
          We shall not be responsible for any third party products or services.
        </p>
        <p>
          22. <strong>SUBMISSIONS:</strong>
        </p>
        <ul>
          <li>
            www.nirmangurunepal.com is free to accept, edit or refuse any materials
            you may send us (collectively, "Submissions") as part of the
            website/app functionality, not limited to, but including, Ratings &amp;
            Review, Ask Your questions to our Expert and testimonials. Such
            submissions are not to be offensive for moral, religious, racial or
            political reasons or violent, illegal, obscene, defamatory, offensive,
            or otherwise intolerable nature. The transmission of such offensive
            material may contravene relevant legislation, regulations and ethics.
          </li>
          <li>
            All existing rights of any kind and nature in relation to submissions
            shall be considered by www.nirmangurunepal.com as being its own. By
            posting, uploading, entering or sending your submissions, you grant the
            Submission for any purpose, unrestricted use, without you being
            compensated, including a non-terminable, non-exclusive and open license
            for using, copying, uploading, publicly displaying, conducting online,
            reproducing, editing, translating and reformatting the submissions; and
            in connection with your submission to publish your name.
          </li>
          <li>
            You also represent and guarantee that you do not infringe upon your
            submissions, misappropriate or violate a third party's patent,
            copyright, trademark, trade secret, moral rights or other intellectual
            property rights, advertising rights or privacy rights.
          </li>
          <li>
            It is forbidden for the user/customer to send, post or transfer any
            information or applications, which includes a virus, worm or other
            harmful component on the www.nirmangurunepal.com website/app.
          </li>
          <li>
            www.nirmangurunepal.com does not endorse any submissions on its
            Website/app and is not responsible for them. We shall not take any
            liability for loss, harm or dependency on the knowledge caused by the
            submissions; including but not limited to comments, opinions and
            reviews posted by the user/customer, which may be incorrect, insulting,
            obscene, threatening or harassing.
          </li>
        </ul>
        <p>
          23. <strong>DISCLAIMERS</strong>
        </p>
        <p>
          You understand that the website/app is a platform that users use to locate
          vendors and experts/ registered experts and make order for service and find
          products. You accept that we are not responsible for any flaws including
          but not limited to the vendors and experts/ registered experts, neglect,
          disability or lack of knowledge from their end and the quality of the goods
          and products provided by the manufacturers. We are not a party to this
          activity, nor do we accept any responsibility resulting from the actions of
          the vendors and expert/registered expert.
        </p>
        <p>
          Even though we authenticate and give consent to the expert/registered
          expert, the creditworthiness and authenticity of the skills of the
          expert/registered expert, are to be checked by you. We cannot be held
          responsible for the same.
        </p>
        <p>
          We are not responsible for any service failure if anything happens, not
          only due to an expert/registered experts’ unavailability but also due to
          cancelations of the order. We cannot be liable for the general actions of
          the expert/registered expert and for any unlawful acts by an
          expert/registered expert we will not be held liable. It is recommended you
          make sure your assets are safe and secure.
        </p>
        <p>
          In general, the contents of the website and of the app are summarized, is
          given for informational purposes only. The website and app content
          including, but not limited to, link, video, text, photos, graphics and
          other visuals; is only for information purposes and shall not constitute
          professional advice or any kind of recommendation.
        </p>
        <p>
          For the contents of any materials provided on the app, we assure no
          accountability. Other website/app visitors are at your own risk, depending
          on any information supplied by us. We shall not be liable for any damage or
          injury caused by any use of any product, information, idea or instruction
          contained in the materials provided. We can alter or discontinue all
          aspects or features of this Website at any time without prior notice.
        </p>
        <p>
          By this you grant us a non-exclusive, universal, permanent, irrevocable,
          free and non-licensable right to exercise in your knowledge, your
          copyright, publicity, and database rights in any now known or unknown
          media, with regard to your data in order to enable us to use the data for
          the purpose of the service provision.
        </p>
        <p>
          You release us and/or its officials and leaders and indemnify them from any
          costs, penalties, liabilities or other effects of any action of the
          website/app Users and specifically waive any claims you may have in
          compliance with any relevant law in particular. Please note that the care
          of minors or persons who act under false pretense can pose risks.
        </p>
        <p>
          The parties agree that the platform will not be held responsible neither to
          the expert/registered expert nor the users/vendors for whatever purpose it
          may be for some service /consultation/contact deal between them.
          Furthermore, the platform/company is not responsible for any technological
          malfunctions of any kind, either by the expert/registered expert or the
          users/vendors.
        </p>
        <p>
          The platform is only an intermediary and is not liable for disputes,
          claims, etc. between the expert/registered expert or the users/vendors for
          whatsoever reason it may be. Both are hereby compensating the client for
          any such allegations.
        </p>
        <p>
          24. <strong>INDEMNIFICATION AND LIMITATION OF LIABILITY</strong>
        </p>
        <p>
          You consent to pay, protect and keep this website/app harmless but not
          limited to including its partner distributors, agents and staff from and
          against all loses, liabilities, lawsuits, damages, proceedings, costs and
          expenses (INCLUDING LEGAL FEES AND DISBURSEMENTS IN CONNECTION THEREWITH
          AND INTEREST CHARGEABLE THEREON) claimed or incurred by us arising out of,
          outcome, or may be payable on account of, any violation or failure to
          perform any act, contract, arrangement or agreement you have been rendered
          or are expected to do so according to these Terms of Use. Further, you
          agree to hold us harmless from any accusations created by or resulting
          from, or in conjunction with, any third party your use of the website/app,
          any claim that your material has caused third-party damage, any breach of
          the terms of service, or the violation of any other rights, including any
          intellectual property rights.
        </p>
        <p>
          We or our owners, managers, staff, partners or suppliers shall not in any
          way be responsible to you or any third party for any special, negative,
          incidental, consequential or punitive damages, including those resulting
          from the lack of use, date or profits, whether it is foreseeable or not or
          if we have been advised or not of the probability of such damages, or on
          the basis of any liability theory, including violation of the contract or
          warranty, Negligence or other torture, or any other argument that occurs,
          or in conjunction with your website/app usage and link, services or
          materials. To the maximum extent allowed by applicable law, the
          restrictions and exclusions in this section apply.
        </p>
        <p>
          25. <strong>TERMINATION:</strong>
        </p>
        <p>
          You accept that www.nirmangurunepal.com, at its sole discretion and for no
          reason whatsoever, including no restriction if you violate these terms and
          conditions, your access and use of the website/app may be terminated at any
          time. You agree that your website/app access can be terminated or your
          account can be suspended without notice, and you accept that you are not
          responsible for any such termination by www.nirmangurunepal.com. Your right
          to use the website/app/service of nirman guru nepal ends immediately upon
          termination of your website/app access/use. <strong> </strong>
        </p>
        <p>
          26. <strong>CHANGES TO THESE TERMS AND CONDITIONS:</strong>
        </p>
        <p>
          We reserve the right, without further notice to you, to change these terms
          and conditions from time to time. Any such changes we make will be
          effective after publishing on the website /app a revised version of these
          Terms and Conditions. It is your duty to check the terms and conditions of
          www.nirmangurunepal.com on a regular basis. The continued use of the
          website/app after any such updates have been made, will constitute the
          decision to obey the revised terms and conditions and will be automatically
          bound by them.
        </p>
        <p>
          27. <strong>MISCELLANEOUS</strong>
        </p>
        <p>
          We reserve the right to change these Terms of Service in its discretion at
          any time without notice and to notify users of any such changes solely by
          modifying these Terms of Service. Upon publishing any updated Terms of
          Service, your continued use of the website/app would constitute your
          willingness to be bound by any such amendments. Upon publishing this Terms
          of Service, the use of this website/app will be regulated by the Terms of
          Service that applied at the time of use.
        </p>
        <p>
          We can alter, cancel, discontinue or limit any portion of the Content at
          any time without notice or liability, including the availability of any
          portion of the Content. For any reason at any time, we can deny access to
          any person or user. Therefore, we can pass rights and obligations under
          this Agreement at any time to any affiliate, subsidiary or business unit,
          or any of its affiliates or divisions, or any entity acquiring us or any of
          its assets.
        </p>
        <p>
          We reserve the right to block the use of the website/app if a customer is
          responsible for any violation of the Terms of Service. In no way can this
          be viewed as a waiver of any legal right under Nepalese law to claim any
          damages or to initiate any legal proceedings against the User.
        </p> */}
      </div>
    </div>
  );
};
