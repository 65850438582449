import { Form, Input } from 'antd';

export default ({ label, type = 'text', name, placeholder, rules }) => {
  return (
    <Form.Item
      className="username"
      label={label}
      name={name}
      rules={rules}
    >
      {type === 'textarea' ?
        <Input.TextArea row={4} />
        :
        <Input type={type} placeholder={placeholder} />
      }
    </Form.Item>
  );
}