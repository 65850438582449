import { setValueOf, getValueOf, removeValueOf, STORAGE_KEYS } from "utils/storage";

export const authenticate = (accessToken) => {
    setValueOf(STORAGE_KEYS.ACCESS_TOKEN, accessToken);
}

export const isAuthenticated = () => {
    let isAuthenticated = false;

    const accessToken = getValueOf(STORAGE_KEYS.ACCESS_TOKEN);

    if (accessToken && accessToken !== '') {
        isAuthenticated = true;
    }

    return isAuthenticated;
}

export const unAuthenticate = (accessToken) => {
    removeValueOf(STORAGE_KEYS.ACCESS_TOKEN);
}