import { useContext } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Carousel } from "antd";

import { CategoryContext } from "context/CategoryContext";

import useActiveCategoriesWithFeaturedProducts from "hooks/category/useActiveCategoriesWithFeaturedProducts";
import useActiveMainBanners from "hooks/banner/useActiveMainBanners";
import useActiveOfferBanners from "hooks/banner/useActiveOfferBanners";
import useActiveAdvertisementBanners from "hooks/banner/useActiveAdvertisementBanners";

import config from "configs/config";

import Category from "./Category";
import ProductSlider from "components/Slider/ProductSlider";
import SpinnerLoader from "components/Loader/SpinnerLoader";

import "./styles.scss";

export default ({}) => {
  const { activeCategoriesLoading, activeCategoriesResult } =
    useContext(CategoryContext);

  const {
    activeCategoriesWithFeaturedProductsResult,
    activeCategoriesWithFeaturedProductsLoading,
  } = useActiveCategoriesWithFeaturedProducts();
  const { activeMainBannersResult, activeMainBannersLoading } =
    useActiveMainBanners();
  const { activeOfferBannersResult, activeOfferBannersLoading } =
    useActiveOfferBanners();
  const {
    activeAdvertisementBannersResult,
    activeAdvertisementBannersLoading,
  } = useActiveAdvertisementBanners();

  return (
    <div className="homepage">
      {activeMainBannersLoading ? (
        <SpinnerLoader />
      ) : activeMainBannersResult ? (
        <Carousel autoplay autoplaySpeed={6000} speed={1000}>
          {activeMainBannersResult.map((b) =>
            b.link ? (
              <Link to={{ pathname: b.link }} target="_blank">
                <div className="homepage_banner" key={b._id}>
                  <img src={`${config.assetURL}/${b.image}`} alt={b.title} />
                </div>
              </Link>
            ) : (
              <div className="homepage_banner" key={b._id}>
                <img src={`${config.assetURL}/${b.image}`} alt={b.title} />
              </div>
            )
          )}
        </Carousel>
      ) : null}

      {activeCategoriesLoading ? (
        <SpinnerLoader />
      ) : activeCategoriesResult ? (
        <div className="homepage_category">
          <Category list={activeCategoriesResult} />
        </div>
      ) : null}

      {activeOfferBannersLoading ? (
        <SpinnerLoader />
      ) : activeOfferBannersResult ? (
        <div className="homepage_offer">
          <Row gutter={[24, 24]}>
            {activeOfferBannersResult.map((ao) =>
              ao.link ? (
                <Col key={ao._id} md={12} style={{ width: "100%" }}>
                  <Link to={{ pathname: ao.link }} target="_blank">
                    <div className="homepage_offer_image">
                      <img
                        src={`${config.assetURL}/${ao.image}`}
                        alt={ao.title}
                      />
                    </div>
                  </Link>
                </Col>
              ) : (
                <Col key={ao._id} md={12} style={{ width: "100%" }}>
                  <div className="homepage_offer_image">
                    <img
                      src={`${config.assetURL}/${ao.image}`}
                      alt={ao.title}
                    />
                  </div>
                </Col>
              )
            )}
          </Row>
        </div>
      ) : null}

      {activeCategoriesWithFeaturedProductsLoading ? (
        <SpinnerLoader />
      ) : activeCategoriesWithFeaturedProductsResult ? (
        activeCategoriesWithFeaturedProductsResult.map((ac) => (
          <div key={ac._id} className="homepage_product">
            <ProductSlider title={ac.title} slug={ac.slug} list={ac.products} />
          </div>
        ))
      ) : null}

      {activeAdvertisementBannersLoading ? (
        <SpinnerLoader />
      ) : activeAdvertisementBannersResult ? (
        <div className="homepage_advertisement">
          <Row gutter={[24, 24]}>
            {activeAdvertisementBannersResult.map((aa) =>
              aa.link ? (
                <Col key={aa._id} md={12} style={{ width: "100%" }}>
                  <Link to={{ pathname: aa.link }} target="_blank">
                    <div className="homepage_offer_image">
                      <img
                        src={`${config.assetURL}/${aa.image}`}
                        alt={aa.title}
                      />
                    </div>
                  </Link>
                </Col>
              ) : (
                <Col key={aa._id} md={12} style={{ width: "100%" }}>
                  <div className="homepage_offer_image">
                    <img
                      src={`${config.assetURL}/${aa.image}`}
                      alt={aa.title}
                    />
                  </div>
                </Col>
              )
            )}
          </Row>
        </div>
      ) : null}
    </div>
  );
};
