import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  InquiryCreate($mobile: String!, $product: String!) {
    inquiryCreate(mobile: $mobile, product: $product) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'inquiryCreate'
  });

  return { inquiryCreateTrigger: trigger, inquiryCreateResult: result, inquiryCreateLoading: loading, inquiryCreateError: error }
}