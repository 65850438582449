import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
ActiveSimilarProductsBySlug($slug: String!) {
  activeSimilarProductsBySlug(slug: $slug) {
    _id
    category {
      _id
      slug
    }
    title
    slug
    price
    offerPrice
    image
    video
  }
}
`;

export default (slug) => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'activeSimilarProductsBySlug',
    variables: { slug },
    fetchPolicy: 'cache-and-network',
    defaultValue: []
  });

  return { activeSimilarProductsBySlugResult: result, activeSimilarProductsBySlugLoading: loading, activeSimilarProductsBySlugError: error };
}