import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  ActiveSetting {
    activeSetting {
      _id
      logo
      address
      primaryPhone
      secondaryPhone
      officePhone
      infoEmail
      officeTime
      facebookLink
      instagramLink
      youtubeLink
    }
  }
`;

export default (id) => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'activeSetting',
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });

  return { activeSettingResult: result, activeSettingLoading: loading, activeSettingError: error };
}