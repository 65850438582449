import { useState, useRef, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import ImageGallery from 'react-image-gallery';
import renderHTML from 'react-render-html';

import useActiveProductBySlug from 'hooks/product/useActiveProductBySlug';
import useActiveSimilarProductsBySlug from 'hooks/product/useActiveSimilarProductsBySlug';
// import useActiveRelatedProductsBySlug from 'hooks/product/useActiveRelatedProductsBySlug';


import routeConfig from 'Routes/config';

import config from 'configs/config';

import * as util from 'utils/util';

import ProductSlider from 'components/Slider/ProductSlider';
import SpinnerLoader from 'components/Loader/SpinnerLoader';
import ProductInquiryModal from 'components/Modal/ProductInquiryModal';
import ProductInquiryCallModal from 'components/Modal/ProductInquiryCallModal';

import './styles.scss';

export default ({ history, match }) => {
  window.scrollTo(0, 0);

  const urlCategory = match.params.category;
  const urlProduct = match.params.product;

  const [showInquiryModal, setShowInquiryModal] = useState(false);
  const [showInquiryCallModal, setShowInquiryCallModal] = useState(false);

  const { activeProductBySlugResult, activeProductBySlugLoading } = useActiveProductBySlug(urlProduct);
  const { activeSimilarProductsBySlugResult, activeSimilarProductsBySlugLoading } = useActiveSimilarProductsBySlug(urlProduct);
  // const { activeRelatedProductsBySlugResult, activeRelatedProductsBySlugLoading } = useActiveRelatedProductsBySlug(urlProduct);

  useEffect(() => {
    if (!activeProductBySlugLoading && activeProductBySlugResult && Object.keys(activeProductBySlugResult).length && Object.keys(activeProductBySlugResult).length > 0) {
      if (!activeProductBySlugResult._id) {
        history.push(routeConfig.homepage.path);
      }
    }
  }, [activeProductBySlugLoading, activeProductBySlugResult])

  const formatImageGalleryItems = (video, images) => {
    let formattedItems = [];

    if (images && images.length) {
      formattedItems = images.map(i => ({ original: `${config.assetURL}/${i}`, thumbnail: `${config.assetURL}/${i}` }));
    };

    if (video && video !== '') {
      const videoId = util.youtubeVideoIDParser(video);

      if (videoId) {
        formattedItems.unshift({
          originalUrl: `https://www.youtube.com/embed/${videoId}`,
          thumbnail: `http://img.youtube.com/vi/${videoId}/2.jpg`
        })
      }

    }

    return formattedItems;
  }

  const videoPlayerRef = useRef();
  const renderImageGalleryItem = (item) => {
    return < div >
      {item.originalUrl ?
        <div className='video-wrapper'>
          <iframe
            width='100%'
            height='495'
            src={item.originalUrl}
            frameBorder='0'
            allowFullScreen
            ref={videoPlayerRef}
          >
          </iframe>
        </div>
        :
        <img className='image-gallery-image' src={item.original} />
      }
    </div >
  }

  const imageGallerySlided = (index) => {
    if (index != 0 && activeProductBySlugResult && activeProductBySlugResult.video && activeProductBySlugResult.video !== '') {
      if (videoPlayerRef && videoPlayerRef.current && activeProductBySlugResult.video) {
        const videoId = util.youtubeVideoIDParser(activeProductBySlugResult.video);

        videoPlayerRef.current.src = `https://www.youtube.com/embed/${videoId}`;
      }
    }
  }

  return (
    <div className='productdetail'>
      {activeProductBySlugResult._id ?
        <>
          <ProductInquiryModal product={activeProductBySlugResult._id} visible={showInquiryModal} onClose={() => setShowInquiryModal(false)} />
          <ProductInquiryCallModal visible={showInquiryCallModal} onClose={() => setShowInquiryCallModal(false)} />
        </>
        :
        null
      }
      <div className='productdetail_breadcrumb'>
        Home &gt; {urlCategory.replaceAll('-', ' ') || null} &gt; {urlProduct.replaceAll('-', ' ') || null}
      </div>
      {activeProductBySlugLoading ?
        <SpinnerLoader />
        :
        activeProductBySlugResult ?
          <>
            <div className='productdetail_detail'>
              <Row>
                <Col md={12} xs={24}>
                  <div className='productdetail_detail_image'>
                    {activeProductBySlugResult.image && activeProductBySlugResult.image.length ?
                      <ImageGallery
                        items={formatImageGalleryItems(activeProductBySlugResult.video, activeProductBySlugResult.image)}
                        renderItem={renderImageGalleryItem}
                        showNav={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        autoPlay={false}
                        onSlide={imageGallerySlided}
                      />
                      :
                      null}
                  </div>
                </Col>
                <Col md={12} xs={24}>
                  <div className='productdetail_detail_info'>
                    <div className='productdetail_detail_info_name'>
                      {activeProductBySlugResult.title}
                    </div>
                    <div className='productdetail_detail_info_specs'>
                      {activeProductBySlugResult.brand ?
                        <div className='productdetail_detail_info_specs_brand'>
                          Brand: <span>{activeProductBySlugResult.brand.title}</span>
                        </div>
                        :
                        null
                      }
                      <div className='productdetail_detail_info_specs_sku'>
                        SKU: <span>{activeProductBySlugResult.sku}</span>
                      </div>
                    </div>
                    <div className='productdetail_detail_info_overview'>
                      {renderHTML(activeProductBySlugResult.description || '')}
                    </div>
                    <div className='productdetail_detail_info_availability'>
                      Avaibality: <span>In Stock</span>
                    </div>
                    <div className='productdetail_detail_info_delivery'>
                      Delivery Date: <span>{activeProductBySlugResult.deliveryTime}</span>
                    </div>
                    <div className='productdetail_detail_info_price'>
                      {activeProductBySlugResult.offerPrice ?
                        <div className='productdetail_detail_info_price_actual'>
                          Actual Price: <span>NRs {util.priceFormatter(activeProductBySlugResult.price)}</span>
                        </div>
                        :
                        null}
                      <div className='productdetail_detail_info_price_offer'>
                        {activeProductBySlugResult.offerPrice ? 'Now Only' : 'Price'}: <span>NRs {activeProductBySlugResult.offerPrice ? util.priceFormatter(activeProductBySlugResult.offerPrice) : util.priceFormatter(activeProductBySlugResult.price)}</span>
                      </div>
                    </div>
                    <div className='productdetail_detail_info_button'>
                      <div className='productdetail_detail_info_button_call'>
                        <Button type='primary' onClick={() => setShowInquiryCallModal(true)}>Call Now</Button>
                      </div>
                      <div className='productdetail_detail_info_button_request'>
                        <Button type='secondary' onClick={() => setShowInquiryModal(true)}>Request Call Back</Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className='productdetail_overview'>
              <div className='productdetail_overview_title'>
                Product Overview
              </div>
              <div className='productdetail_overview_description'>
                {renderHTML(activeProductBySlugResult.overview || '')}
              </div>
            </div>
          </>
          : null}

      {activeSimilarProductsBySlugLoading ?
        <SpinnerLoader />
        :
        activeSimilarProductsBySlugResult ?
          <div className='productdetail_similar'>
            <ProductSlider title='Customers who bought this item also bought' list={activeSimilarProductsBySlugResult} />
          </div>
          :
          null
      }

      {/* {activeRelatedProductsBySlugLoading ?
        <SpinnerLoader />
        :
        activeRelatedProductsBySlugResult ?
          <div className='productdetail_related'>
            <ProductSlider title='Related Products' list={activeRelatedProductsBySlugResult} />
          </div>
          :
          null
      } */}
    </div>
  );
}