export const isLocalHost = () => {
  const hostname = window.location.hostname;
  return !!(
    hostname.indexOf('localhost') > -1 ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  )
};

export const isDev = () => {
  const hostname = window.location.hostname;
  return !!(
    hostname.indexOf('dev') > -1
  )
};

export const authHeader = token => {
  return ({
    context: {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }
  });
};

export const youtubeVideoIDParser = (url) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  var match = url.match(regExp);
  return (match && match[7].length == 11) ? match[7] : false;
}

export const priceFormatter = (price) => {
  return `${price}`.replace(/.(?=(..)*...$)/g, '$&,')
}

export const extractValueFromQueryString = (queryString, key) => {
  let value = null;

  if (queryString) {
    const queryStringObject = queryString.replace('?', '').split('&').reduce((init, fqs) => {
      const key = fqs.split('=')[0]
      const value = fqs.split('=')[1]

      init[key] = value

      return init
    }, {})

    if (queryStringObject && Object.keys(queryStringObject).length) {
      if (key) {
        if (queryStringObject[key]) {
          value = queryStringObject[key]
        }
      } else {
        value = queryStringObject
      }
    }
  }

  return value
}