import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  ContactMessageCreate($name: String!, $mobile: String!, $message: String!) {
    contactMessageCreate(name: $name, mobile: $mobile, message: $message) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'contactMessageCreate'
  });

  return { contactMessageCreateTrigger: trigger, contactMessageCreateResult: result, contactMessageCreateLoading: loading, contactMessageCreateError: error }
}