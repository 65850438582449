import { ApolloProvider } from '@apollo/client';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import { SettingProvider } from 'context/SettingContext';
import { CategoryProvider } from 'context/CategoryContext';

import client from 'configs/graphqlClient';

import Routes from 'Routes';

import 'antd/dist/antd.css';
import 'react-multi-carousel/lib/styles.css';
import 'assets/styles/main.scss';

export default ({ }) => {
  return (
    <ApolloProvider client={client}>
      <SettingProvider>
        <CategoryProvider>
          <Routes />
          <MessengerCustomerChat
            pageId="102279485758408"
            appId="1163231431169622"
          />
        </CategoryProvider>
      </SettingProvider>
    </ApolloProvider>
  );
}