import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  ActiveMainBanners {
    activeMainBanners {
      _id
      title
      link
      image
    }
  }
`;

export default () => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'activeMainBanners',
    fetchPolicy: 'cache-and-network',
    defaultValue: []
  });

  return { activeMainBannersResult: result, activeMainBannersLoading: loading, activeMainBannersError: error };
}