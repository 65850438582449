import { Row, Col, Grid } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'

import routeConfig from 'Routes/config';

import config from 'configs/config';

import './styles.scss';

export default ({ list }) => {
  // const { useBreakPoint } = Grid;
  // const screens = useBreakPoint();
  // console.log({ screens })

  let formattedList = {
    length: 0,
    list: []
  };

  if (list) {
    let listGroup = [];
    list.forEach((l, i) => {
      if (i === 0 || (i + 1 === list.length && listGroup.length === 0)) {
        formattedList.list.push(l);
      } else {
        listGroup.push(l);

        if (listGroup.length == 2) {
          formattedList.list.push(listGroup);
          listGroup = [];
        }
      }
    });
  }

  formattedList.length = formattedList.list.length;

  return (
    <div className='category'>
      <Row gutter={[12, 12]}>
        {formattedList.list.map((l, i) =>
          <Col key={i} md={24 / formattedList.length} xs={24}>
            {Array.isArray(l) ?
              <Row gutter={[12, 12]}>
                {l.map((cl, i) =>
                  <Col key={i} md={24} xs={24}>
                    <div className='category_item double' style={{ backgroundImage: `url(${config.assetURL}/${cl.image})` }}>
                      <div className='category_item_overlay' />
                      <Link to={routeConfig.productList.path.replace(':category', cl.slug)}>
                        <div className='category_item_text'>
                          {cl.title} <FontAwesomeIcon icon={faChevronCircleRight} />
                        </div>
                      </Link>
                    </div>
                  </Col>
                )}
              </Row>
              :
              <div className='category_item' style={{ backgroundImage: `url(${config.assetURL}/${l.image})` }}>
                <div className='category_item_overlay' />
                <Link to={routeConfig.productList.path.replace(':category', l.slug)}>
                  <div className='category_item_text'>
                    {l.title} <FontAwesomeIcon icon={faChevronCircleRight} />
                  </div>
                </Link>
              </div>
            }
          </Col>
        )}
      </Row>
    </div>
  );
}