import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  ActiveAdvertisementBanners {
    activeAdvertisementBanners {
      _id
      title
      link
      image
    }
  }
`;

export default () => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: "activeAdvertisementBanners",
    fetchPolicy: "cache-and-network",
    defaultValue: [],
  });

  return {
    activeAdvertisementBannersResult: result,
    activeAdvertisementBannersLoading: loading,
    activeAdvertisementBannersError: error,
  };
};
